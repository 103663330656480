'use strict';

const AuditTrailCategories = require('./audit-trail-categories');
const FriendlyAuditTrailEvents = require('./friendly-audit-trail-events');
const Events = require('./events');

const eventsToCategory = Object.values(FriendlyAuditTrailEvents).reduce((hash, event) => {

    let category;
    switch (event) {
        case FriendlyAuditTrailEvents[Events.UNKNOWN_EMAIL_AT_CONNECTOR]:
        case FriendlyAuditTrailEvents[Events.USER_LOGGEDIN]:
        case FriendlyAuditTrailEvents[Events.USER_CREATED]:
        case FriendlyAuditTrailEvents[Events.LOCK_OUT]:
        case FriendlyAuditTrailEvents[Events.ADMIN_USER_LOCK_OUT]:
        case FriendlyAuditTrailEvents[Events.USER_PROFILE_UPDATED]:
        case FriendlyAuditTrailEvents[Events.USER_UPDATED_CONSENT]:
        case FriendlyAuditTrailEvents[Events.USER_AGREED_TERMS_OF_USE]:
        case FriendlyAuditTrailEvents[Events.USER_DECLINED_TERMS_OF_USE]:
        case FriendlyAuditTrailEvents[Events.USER_PASSWORD_CHANGED]:
        case FriendlyAuditTrailEvents[Events.USER_EMAIL_CHANGED]:
            category = AuditTrailCategories.USERS;
            break;
        case FriendlyAuditTrailEvents[Events.TEAM_CREATED]:
        case FriendlyAuditTrailEvents[Events.TEAM_CREATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.TEAM_UPDATED]:
        case FriendlyAuditTrailEvents[Events.TEAM_RENAMED]:
        case FriendlyAuditTrailEvents[Events.TEAM_TIMEZONE_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_PRINTER_BINDER_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_PASSWORD_POLICY_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_SIGNATURE_POLICY_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_FORM_PLACEHOLDER_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_SIGNING_PIN_POLICY_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_SSO_POLICY_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_TWO_FACTOR_AUTHENTICATION_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_TIMEOUT_PERIOD_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LABEL_CREATED]:
        case FriendlyAuditTrailEvents[Events.LABEL_CREATED_VIA_STUDY_PROFILE]:
        case FriendlyAuditTrailEvents[Events.LABEL_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LABEL_DELETED]:
        case FriendlyAuditTrailEvents[Events.TEAM_AUTOMATIC_BINDER_OWNER_SETTING_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_AUTOMATIC_DOCUMENT_OWNER_SETTING_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_SUPPORT_CONTACT_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_SIP_INTEGRATION_LINK_CREATED]:
        case FriendlyAuditTrailEvents[Events.TEAM_SIP_INTEGRATION_LINK_UPDATED]:
        case FriendlyAuditTrailEvents[Events.TEAM_SIP_INTEGRATION_LINK_DELETED]:
        case FriendlyAuditTrailEvents[Events.TEAM_DEACTIVATION_SCHEDULED]:
        case FriendlyAuditTrailEvents[Events.TEAM_DEACTIVATED]:
        case FriendlyAuditTrailEvents[Events.TEAM_REACTIVATED]:
        case FriendlyAuditTrailEvents[Events.TEAM_DEACTIVATION_CANCELED]:
        case FriendlyAuditTrailEvents[Events.STUDY_ROLE_CREATED]:
        case FriendlyAuditTrailEvents[Events.STUDY_ROLE_UPDATED]:
        case FriendlyAuditTrailEvents[Events.STUDY_ROLE_REMOVED]:
            category = AuditTrailCategories.TEAM_PROFILE;
            break;
        case FriendlyAuditTrailEvents[Events.LOG_TEMPLATE_CREATED]:
        case FriendlyAuditTrailEvents[Events.LOG_TEMPLATE_CREATED_VIA_SITELINK]:
        case FriendlyAuditTrailEvents[Events.LOG_TEMPLATE_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LOG_TEMPLATE_MARKED_AS_ACTIVE]:
        case FriendlyAuditTrailEvents[Events.LOG_TEMPLATE_MARKED_AS_INACTIVE]:
        case FriendlyAuditTrailEvents[Events.LOG_TEMPLATE_CLONED]:
            category = AuditTrailCategories.LOG_TEMPLATES;
            break;
        case FriendlyAuditTrailEvents[Events.ANNOUNCEMENT_SENT]:
        case FriendlyAuditTrailEvents[Events.ANNOUNCEMENT_VIEWED]:
            category = AuditTrailCategories.ANNOUNCEMENTS;
            break;
        case FriendlyAuditTrailEvents[Events.ROLE_CREATED]:
        case FriendlyAuditTrailEvents[Events.ROLE_CREATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.ROLE_DELETED]:
        case FriendlyAuditTrailEvents[Events.ROLE_DELETED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.ROLE_PERMISSIONS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.USER_PERMISSIONS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.ALL_USER_PERMISSIONS_REMOVED]:
        case FriendlyAuditTrailEvents[Events.ALL_USER_PERMISSIONS_REMOVED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.ROLE_CLONED]:
        case FriendlyAuditTrailEvents[Events.ROLE_CLONED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.ROLE_RENAMED]:
        case FriendlyAuditTrailEvents[Events.ROLE_RENAMED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.ROLE_BLINDED_STATUS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.ROLE_BLINDED_STATUS_UPDATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.ROLE_ACCESS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.BLINDED_ROLE_ACCESS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.UNBLINDED_ROLE_ACCESS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.USER_ALIAS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.TEAM_USER_ADDED]:
        case FriendlyAuditTrailEvents[Events.TEAM_USER_REMOVED]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_ADDED_TO_ROLE]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_ADDED_TO_ROLE_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_ADDED_TO_BLINDED_ROLE]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_ADDED_TO_BLINDED_ROLE_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_ADDED_TO_UNBLINDED_ROLE]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_ADDED_TO_UNBLINDED_ROLE_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_REMOVED_FROM_ROLE]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_REMOVED_FROM_BLINDED_ROLE]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_REMOVED_FROM_UNBLINDED_ROLE]:
        case FriendlyAuditTrailEvents[Events.TEAM_USERS_REMOVED_FROM_ROLE_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.TEAM_USER_NAME_CHANGED]:
        case FriendlyAuditTrailEvents[Events.TEAM_USER_PROFILE_CREATED]:
            category = AuditTrailCategories.TEAM_MEMBERS;
            break;
        case FriendlyAuditTrailEvents[Events.BINDER_CREATED]:
        case FriendlyAuditTrailEvents[Events.BINDER_CREATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.BINDER_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.BINDER_RENAMED]:
        case FriendlyAuditTrailEvents[Events.BINDER_RENAMED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.BINDER_DELETED]:
        case FriendlyAuditTrailEvents[Events.BINDER_SIGNATURE_JOB_TITLE_ADDED]:
        case FriendlyAuditTrailEvents[Events.BINDER_SIGNATURE_JOB_TITLE_REMOVED]:
        case FriendlyAuditTrailEvents[Events.BINDER_LABELS_UPDATED_VIA_STUDY_PROFILES]:
        case FriendlyAuditTrailEvents[Events.FOLDER_CREATED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_CREATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_MOVED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_CLONED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DELETED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_RENAMED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOCUMENT_CREATED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOCUMENT_DELETED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOCUMENT_MOVED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOCUMENT_CLONED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOCUMENT_RENAMED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOCUMENT_MOVED_TO]:
        case FriendlyAuditTrailEvents[Events.FOLDER_DOCUMENT_CLONED_TO]:
        case FriendlyAuditTrailEvents[Events.FOLDER_FOLDER_CREATED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_FOLDER_DELETED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_FOLDER_MOVED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_FOLDER_CLONED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_FOLDER_RENAMED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_FOLDER_MOVED_TO]:
        case FriendlyAuditTrailEvents[Events.FOLDER_FOLDER_CLONED_TO]:
        case FriendlyAuditTrailEvents[Events.FOLDER_SIGNATURE_JOB_TITLE_ADDED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_SIGNATURE_JOB_TITLE_REMOVED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_LABELS_UPDATED_VIA_STUDY_PROFILES]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_DELETED]:
        case FriendlyAuditTrailEvents[Events.SIGNATURE_REQUEST]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_CREATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_IMPORTED_VIA_EHUB]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_IMPORTED_VIA_PRINTER]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_IMPORTED_VIA_EXTERNAL_INTEGRATION]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_IMPORTED_VIA_EMAIL]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_IMPORTED_VIA_DOC_SHARING]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_SUCCESSFULLY_SHARED_VIA_DOC_SHARING]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_FAILED_FOR_SHARING_VIA_DOC_SHARING]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REPLACED_VIA_EMAIL]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REPLACED_VIA_PRINTER]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_SIGNATURE_REQUESTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_SIGNATURE_REQUEST_REMIND]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_SIGNATURE_REQUEST_DELETED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_SIGNED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_DECLINED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_PLACED_SIGNATURE]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REDACTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_HIGHLIGHTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_TEXT_STRIKEOUT]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_NOTATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_STAMPED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_FORM_UPDATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_FORM_FINALIZED]:
        case FriendlyAuditTrailEvents[Events.REVISION_REQUEST]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REPLACED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_MOVED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_LOCKED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_UNLOCKED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_CLONED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_BULK_CLONED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_RENAMED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_VIEWED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_RECEIVED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_ACCEPTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_DUE_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_EXP_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.EMAIL_CONNECTOR_PROCESSED_INBOUND_MESSAGE]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_REJECTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_CANCELLED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_APPROVED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_TAGS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_CATEGORY_UPDATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_SIP_STATUS_CREATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_EDITED_PAGES_DELETED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_EDITED_PAGES_ROTATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_EDITED_PAGES_REORDERED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_FILLED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_FILLED_VIA_EMAIL]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_FILLED_VIA_PRINTER]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_DELETED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_CREATED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_MOVED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_CLONED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_BULK_CLONED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_RENAMED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_VIEWED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_DUE_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.PLACEHOLDER_TAGS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_DELETED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_CREATED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_CREATED_FROM]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_SIGNED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_DECLINED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_MOVED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_RENAMED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_VIEWED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_DUE_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_EXP_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_ORIGINAL_DOC_DELETED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_BROKEN_PLACEHOLDER_FILLED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_TAGS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LOG_DELETED]:
        case FriendlyAuditTrailEvents[Events.LOG_CREATED]:
        case FriendlyAuditTrailEvents[Events.LOG_CREATED_VIA_SITELINK]:
        case FriendlyAuditTrailEvents[Events.LOG_MOVED]:
        case FriendlyAuditTrailEvents[Events.LOG_RENAMED]:
        case FriendlyAuditTrailEvents[Events.LOG_VIEWED]:
        case FriendlyAuditTrailEvents[Events.LOG_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.LOG_EXP_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.LOG_TAGS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LOG_SIGNATURE_REQUESTED]:
        case FriendlyAuditTrailEvents[Events.LOG_SIGNATURE_REQUEST_REMIND]:
        case FriendlyAuditTrailEvents[Events.LOG_SIGNATURE_REQUEST_DELETED]:
        case FriendlyAuditTrailEvents[Events.LOG_SIGNED]:
        case FriendlyAuditTrailEvents[Events.LOG_DECLINED]:
        case FriendlyAuditTrailEvents[Events.LOG_DETAILS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LOG_NEW_VERSION_CREATED]:
        case FriendlyAuditTrailEvents[Events.LOG_LEGEND_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LOG_INFORMATION_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_CREATED]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_UPDATED]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_SIGNATURE_REQUESTED]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_REQUEST_REMIND]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_REQUEST_DELETED]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_SIGNED]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_DECLINED]:
        case FriendlyAuditTrailEvents[Events.LOG_ENTRY_STRIKETHROUGH]:
        case FriendlyAuditTrailEvents[Events.BINDER_LABELS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.FOLDER_LABELS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.MONITOR_REVIEW_OPEN_QUERY]:
        case FriendlyAuditTrailEvents[Events.MONITOR_REVIEW_CLOSED_QUERY]:
        case FriendlyAuditTrailEvents[Events.MONITOR_REVIEW_COMMENT]:
        case FriendlyAuditTrailEvents[Events.MONITOR_REVIEW_DOCUMENT_APPROVED]:
        case FriendlyAuditTrailEvents[Events.MONITOR_REVIEW_DOCUMENT_REVIEWED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_INTEGRATION_RESPONSE_OK]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_INTEGRATION_RESPONSE_ERROR]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_MARKED_AS_PHI]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_UNMARKED_AS_PHI]:
        case FriendlyAuditTrailEvents[Events.TASK_REMINDER]:
        case FriendlyAuditTrailEvents[Events.TASK_CREATED]:
        case FriendlyAuditTrailEvents[Events.TASK_UPDATED]:
        case FriendlyAuditTrailEvents[Events.TASK_DELETED]:
        case FriendlyAuditTrailEvents[Events.TASK_ASSIGNED]:
        case FriendlyAuditTrailEvents[Events.TASK_WITHDRAWN]:
        case FriendlyAuditTrailEvents[Events.ARCHIVAL_SCHEDULED]:
        case FriendlyAuditTrailEvents[Events.ARCHIVAL_COMPLETED]:
        case FriendlyAuditTrailEvents[Events.ARCHIVE_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.ARCHIVAL_CANCELED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_CREATED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_ACCEPTED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_DECLINED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_APPROVED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_REJECTED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_DELETED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEWER_CHANGED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_CYCLE_REMOVED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_ADDED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_REJECTION_DISAGREED]:
        case FriendlyAuditTrailEvents[Events.QC_REVIEW_REJECTION_AGREED]:
        case FriendlyAuditTrailEvents[Events.TEXT_ANNOTATION_ADDED_TO_DOCUMENT]:
        case FriendlyAuditTrailEvents[Events.TIMESTAMP_ANNOTATION_ADDED_TO_DOCUMENT]:
        case FriendlyAuditTrailEvents[Events.LOG_ROLES_RESPONSIBILITIES_UPDATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_EXTRACTED]:
            category = AuditTrailCategories.FOLDER_STRUCTURE;
            break;
        case FriendlyAuditTrailEvents[Events.TAG_CREATED]:
        case FriendlyAuditTrailEvents[Events.TAG_DELETED]:
        case FriendlyAuditTrailEvents[Events.TAG_ASSIGNED]:
        case FriendlyAuditTrailEvents[Events.TAG_UPDATED]:
            category = AuditTrailCategories.TAGS;
            break;

        case FriendlyAuditTrailEvents[Events.CHILD_FOLDER_PATH_UPDATED]:
        case FriendlyAuditTrailEvents[Events.CHILD_DOCUMENT_STUDY_ATTRIBUTES_ASSIGNED]:
        case FriendlyAuditTrailEvents[Events.CHILD_DOCUMENT_PATH_UPDATED]:
        case FriendlyAuditTrailEvents[Events.CHILD_PLACEHOLDER_PATH_UPDATED]:
        case FriendlyAuditTrailEvents[Events.CHILD_SHORTCUT_PATH_UPDATED]:
        case FriendlyAuditTrailEvents[Events.CHILD_LOG_PATH_UPDATED]:
        case FriendlyAuditTrailEvents[Events.CHILD_FOLDER_MOVED]:
        case FriendlyAuditTrailEvents[Events.CHILD_DOCUMENT_MOVED]:
        case FriendlyAuditTrailEvents[Events.CHILD_PLACEHOLDER_MOVED]:
        case FriendlyAuditTrailEvents[Events.CHILD_SHORTCUT_MOVED]:
        case FriendlyAuditTrailEvents[Events.CHILD_LOG_MOVED]:
        case FriendlyAuditTrailEvents[Events.CHILD_BINDER_LABELS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.CHILD_FOLDER_LABELS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_APPROVE_REJECT_STARTED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_FORM_CHECK_COMPLETED]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_ORIGINAL_DOC_DUE_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_ORIGINAL_DOC_EXP_DATE_SET]:
        case FriendlyAuditTrailEvents[Events.SHORTCUT_ORIGINAL_PLACEHOLDER_FILLED]:
        case FriendlyAuditTrailEvents[Events.USER_ENTERS_TEAM]:
        case FriendlyAuditTrailEvents[Events.TEAM_FEATURES_UPDATED]:
        case FriendlyAuditTrailEvents[Events.DOCUMENT_EXPORTED_TO_INTEGRATION]:
        case FriendlyAuditTrailEvents[Events.ARCHIVAL_STARTED]:
            category = AuditTrailCategories.INTERNALS;
            break;
        case FriendlyAuditTrailEvents[Events.USER_PROFILE_CREATED]:
        case FriendlyAuditTrailEvents[Events.USER_NAME_CHANGED]:
        case FriendlyAuditTrailEvents[Events.USER_PROFILE_INFORMATION_CHANGED]:
            category = AuditTrailCategories.USER_PROFILE;
            break;
        case FriendlyAuditTrailEvents[Events.STUDY_TEMPLATE_IMPORTED]:
        case FriendlyAuditTrailEvents[Events.STUDY_TEMPLATE_DOWNLOADED]:
        case FriendlyAuditTrailEvents[Events.STUDY_TEMPLATE_DELETED]:
            category = AuditTrailCategories.STUDY_STRUCTURE_TEMPLATES;
            break;

        case FriendlyAuditTrailEvents[Events.STUDY_CREATED]:
        case FriendlyAuditTrailEvents[Events.STUDY_CREATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.STUDY_UPDATED]:
        case FriendlyAuditTrailEvents[Events.STUDY_UPDATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.SITE_CREATED]:
        case FriendlyAuditTrailEvents[Events.SITE_CREATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.SITE_UPDATED]:
        case FriendlyAuditTrailEvents[Events.SITE_UPDATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.STUDY_BINDERS_AND_FOLDERS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.STUDY_PAYWALLS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.PAYWALL_CREATED]:
        case FriendlyAuditTrailEvents[Events.PAYWALL_UPDATED]:
        case FriendlyAuditTrailEvents[Events.SITE_BINDERS_AND_FOLDERS_UPDATED]:
        case FriendlyAuditTrailEvents[Events.SITE_BINDERS_AND_FOLDERS_UPDATED_VIA_WORKFLOW_API]:
        case FriendlyAuditTrailEvents[Events.STUDY_STARTUP_STARTED]:
        case FriendlyAuditTrailEvents[Events.STUDY_STARTUP_COMPLETED]:
        case FriendlyAuditTrailEvents[Events.STUDY_STARTUP_FAILED]:
            category = AuditTrailCategories.STUDY_PROFILES;
            break;
        default:
            category = AuditTrailCategories.UNCATEGORIZED;
            break;
    }
    hash[event] = category;
    return hash;
}, {});

module.exports = eventsToCategory;
