import { IRoleChipDetails } from '../types/i-role-chip-details';

export class RoleChipDetails implements IRoleChipDetails {
    constructor(
        public name,
        public isActive,
        public trainingStatus,
        public start?
    ) {}
}
