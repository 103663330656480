'use strict';

module.exports = {
    REDACTS: 'redacts',
    HIGHLIGHTS: 'highlights',
    FREE_TEXTS: 'freeTexts',
    TIMESTAMPS: 'timestamps',
    STAMP_SIGNATURES: 'stampSignatures',
    STRIKEOUT: 'strikeout'
};
