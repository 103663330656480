import * as _ from 'lodash';
import * as Sentry from '@sentry/browser';
import {
    BehaviorSubject, Observable, Subject, forkJoin, interval, merge, of,
    throwError
} from 'rxjs';
import { StateService } from '@uirouter/angular';
import {
    Team,
    Crumb,
    Document,
    DocumentId,
    MonitorReview,
    Study,
    User,
    SignatureRequest,
    AuditTrailSubject,
    LogEntry,
    DocumentPathArray,
    LogDetail,
    Task,
    AppConfig as AppConfigValue,
    StudyWithTeamSipIntegrationLink
} from '@app/shared/models';
import { GetJobTitleRequiredResponse, NavParams } from '@app/shared/documents/documents.service.types';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StudiesService } from '@app/shared/studies/studies.service';
import { ActionBarService } from '@app/shared/action-bar/action-bar.service';
import { ActionBarConfig } from '@app/shared/action-bar/action-bar.service.types';
import { UsersService } from '@app/shared/users/users.service';
import { SigningPasscodeService } from '@app/shared/signing-passcode/signing-passcode.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { DocumentService } from '@app/shared/documents/document.service';
import { StudyRolesService } from '@app/shared/study-roles/study-roles.service';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import {
    catchError,
    filter,
    map, switchMap, take, takeUntil, tap
} from 'rxjs/operators';
import {
    FormSaveEvent, AnnotationSaveEvent, ClearAllEvent
} from '@florencehealthcare/doc-viewer';

import { AppConfigService } from '@app/shared/app-config/app-config.service';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { BulkSignBody, BulkSignOrDeclineBody, SignLogEntriesResponse } from '@app/shared/documents-log-entries/document-log-entries.service.types';
import { ModalRef } from '@app/shared/modal-helper/modals.service.types';
import { DownloadsService } from '@app/shared/downloads/downloads.service';
import { MissingSigningPasscodeSubmitEvent } from '@app/components/documents/components/missing-signing-passcode/missing-signing-passcode.component.types';
import { JobTitleRequiredEvent } from '@app/widgets/job-title-required/job-title-required.types';
import { DocumentDeclineQcReviewComponent } from '@app/components/qc-reviews/components/document-decline-qc-review/document-decline-qc-review.component';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { DocumentHistoryService } from '@app/shared/document-history/document-history.service';
import {
    GetDocumentHistoriesPathParams, GetDocumentHistoriesQueryParams
} from '@app/shared/document-history/document-history.service.types';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionsService } from '@app/shared/sessions/sessions.service';
import { TeamService } from '@app/shared/teams/team.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import {
    Component, Inject, OnDestroy, OnInit
} from '@angular/core';
import { JobTitleRequiredComponent } from '@app/widgets/job-title-required/job-title-required.component';
import { ApproveReviewEvent, RejectReviewEvent } from '@app/components/qc-reviews/components/document-qc-review/document-handle-qc-review.component.types';
import { Pendo } from '@app/core/analytics/pendo-script-initializer.service.types';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { SignLogEntriesEvent, DeclineLogEntryEvent } from '../document-log-entries/document-log-entries.component.types';
import { DocumentDeclineData, DocumentDeclineEventEmitter } from '../document-decline/document-decline.component.types';
import { DocumentSignData, SignDocumentCloseReason, SignDocumentSaveEvent } from '../../components/sign-document/sign-document.component.types';
import { SignLogEntriesCloseReason, SignLogEntriesSaveEvent } from '../../components/sign-log-entries/sign-log-entries.component.types';
import { EditDetailsEvent } from '../../components/document-edit-details/document-edit-details.component.types';
import { DocumentMonitorReviewService } from '../../document-monitor-review.service';
import { CreateAnnotationsEvent } from '../../components/annotations-modal/annotations-modal.component.types';
import { LogEditMetadataEvent } from '../../components/log-edit-metadata/log-edit-metadata.component.types';
import { DocumentEditDetailsComponent } from '../../components/document-edit-details/document-edit-details.component';
import { QCReviewService } from '../../qc-review.service';
import { DocumentAnnotationsService } from '../../document-annotation.service';
import { DocumentFormService } from '../../document-form.service';
import { QcReview, QcReviewDeclineParam } from '../../qc-review.services.types';
import { QcAcceptEvent, QcDeclineEvent } from '../../components/log-toolbar/log-toolbar.component.types';
import { DocumentReplaceComponent } from '../../components/document-replace/document-replace.component';
import { OnSubmitDocumentReplace } from '../../components/document-replace/document-replace.component.types';
import { AnnotationsClearAllModalComponent } from '../../components/annotations-clear-all-modal/annotations-clear-all-modal.component';
import { DocumentDownloadComponent } from '../../components/document-download/document-download.component';
import { SignatureCompleteComponent } from '../../components/signature-complete/signature-complete.component';
import { DeclineDocumentComponent } from '../document-decline/document-decline.component';
import { DocumentHistoryModalComponent } from '../../components/document-history-modal/document-history-modal.component';
import { NdvFormSaveComponent } from '../../components/ndv-form-save/ndv-form-save.component';
import { SaveFormModalEvent } from '../../components/ndv-form-save/ndv-form-save.component.types';
import { DoaLogTemplateRolesResponsibilitiesEditComponent } from '../../../log-templates/components/doa-log-template/doa-log-template-roles-responsibilities-edit/doa-log-template-roles-responsibilities-edit.component';
import { TaskFormComponent } from '../../components/task-form/task-form.component';
import { TaskSaveEvent, TaskDestroyEvent } from '../../components/task-form/task-form.component.types';
import style from './document-show.component.scss';
import template from './document-show.component.html';
import { AnnotationsModalComponent } from '../../components/annotations-modal/annotations-modal.component';
import { SignLogEntriesComponent } from '../../components/sign-log-entries/sign-log-entries.component';
import { MissingSigningPasscodeComponent } from '../../components/missing-signing-passcode/missing-signing-passcode.component';
import { SignDocumentComponent } from '../../components/sign-document/sign-document.component';
import { LogEditMetadataComponent } from '../../components/log-edit-metadata/log-edit-metadata.component';
import { StrikeThroughLogEntriesEvent } from '../../components/log-entry-strikethrough/log-entry-strikethrough.component.types';
import { MonitorReviewsUpdateEvent } from '../../components/monitor-review-actions/monitor-review-actions.component.types';
import { USER_TRAINING_ERRORS } from '../../../../shared/user-training/constants/error-messages';


@Component({
    selector: 'document-show',
    template,
    styles: [String(style)]
})
export class DocumentsShowComponent implements OnInit, OnDestroy {
    private stateParams: { [paramName: string]: string };

    signatureQueue: ActionBarConfig;
    loadingSignatureQueue = true;
    taskQueue: ActionBarConfig;
    loadingTaskQueue = true;
    trainingQueue: ActionBarConfig;
    loadingTrainingQueue = false;
    loadingDoc = true;
    doc: Document;
    currentUser: User;
    currentTeam: Team;
    crumbs: Crumb[] = [];
    monitorReviews: MonitorReview[];
    monitorReview: MonitorReview;
    private studies: StudyWithTeamSipIntegrationLink[];
    private viewableStudy = new BehaviorSubject<Study>(undefined);
    public viewableStudy$ = this.viewableStudy.asObservable();
    hasUnviewableStudies: boolean;
    monitorReviewsEnabled: boolean;
    pendingSignatureRequest: SignatureRequest;
    requestIsPastDue: boolean;
    teamSignatureRestricted: boolean;
    isPlaceholderOrPlaceholderShortcut = false;
    isLogDocument = false;
    currentDisplayVersion: number;
    jobTitleRequired = false;
    jobTitleLoaded = false;
    appConfigValue: AppConfigValue;
    documentUrl: string;
    ebindersApiUrl: string;
    token: Promise<string>;
    webviewerServerRangeRequests: boolean;
    webviewerServerRangeRequestsLoaded = false;
    logEntryToUpdate: string | string[];
    pageManipulationFF = false;
    private editDetailsModal: ModalRef<DocumentEditDetailsComponent>;
    private isSigning = false;
    checkingSignatures = false;
    canCopyText = false;
    canStrikeoutText = false;

    readonly formStatusCheckInterval = 5000;
    private formStatusResolved$ = new Subject();
    private destroy$ = new Subject();

    constructor(
        @Inject('Window') private $window: Window & { pendo: Pendo },
        private $state: StateService,
        private AppConfig: AppConfigService,
        private LogEntries: DocumentLogEntriesService,
        private MonitorReviews: DocumentMonitorReviewService,
        private Studies: StudiesService,
        private Teams: TeamService,
        private Users: UsersService,
        private SigningPasscode: SigningPasscodeService,
        private CurrentSession: CurrentSessionService,
        private ActionBar: ActionBarService,
        private AuditTrail: AuditTrailService,
        private DocumentHistory: DocumentHistoryService,
        private Downloads: DownloadsService,
        private Notifications: NotificationsService,
        private ApiError: ApiErrorsService,
        private Modals: ModalsService,
        private QCReviews: QCReviewService,
        private DocumentAnnotations: DocumentAnnotationsService,
        private DocumentForms: DocumentFormService,
        private Sessions: SessionsService,
        private Documents: DocumentService,
        private StudyRoles: StudyRolesService,
        private FeatureFlags: FeatureFlagService
    ) { }

    ngOnInit(): void {
        this.webviewerServerRangeRequests = this.CurrentSession.getCanUsePartialRequests();
        this.webviewerServerRangeRequestsLoaded = true;
        this.token = this.Sessions.heartbeat();
        this.appConfigValue = this.AppConfig.config;
        this.ebindersApiUrl = this.AppConfig.config.ebindersApiUrl;
        this.stateParams = this.$state.params;

        this.Documents.load(this.stateParams.documentId, this.stateParams.version, this.stateParams.contentVersion)
            .subscribe(
                (loadedDocument) => {
                    this.doc = loadedDocument;
                    this.documentUrl = this.constructDocumentFileUrl();
                    this.isPlaceholderOrPlaceholderShortcut = this.doc.subType === 'placeholder' || this.doc.originalDocumentSubType === 'placeholder';
                    this.isLogDocument = this.doc.subType === 'log';

                    this.currentTeam = this.CurrentSession.getCurrentTeam();
                    this.currentUser = this.CurrentSession.getCurrentUser();

                    if (this.doc.formStatus === 'checking-form') {
                        this.watchForFormStatusChanges();
                    }

                    this.getMonitorReviews();
                    this.getStudies();
                    this.checkPendingSignatures();
                    this.crumbs = this.getCrumbs();

                    if (this.stateParams.taskId) {
                        const task = this.doc.tasks.find((singleTask: Task) => singleTask.id === this.stateParams.taskId);
                        this.openTask(task);
                    }
                    else {
                        this.openDetailsIfMissing();
                    }
                    this.loadSignatureQueue().subscribe();
                    this.loadTaskQueue();
                    this.loadTrainingQueueIfTeamHasTrainingGate();
                    this.currentDisplayVersion = this.Documents.getCurrentDocDisplayVersion(this.doc);

                    this.Documents.getDocumentJobTitleRequired(this.getDocumentId()).subscribe(
                        (result: GetJobTitleRequiredResponse) => {
                            if (result && result.jobTitleRequired) {
                                const { jobTitleRequired } = result;
                                this.jobTitleRequired = jobTitleRequired;
                            }
                            this.jobTitleLoaded = true;
                        },
                        () => this.Notifications.unexpectedError()
                    );

                    this.FeatureFlags.getFlag(FEATURE_FLAGS.NDV_PAGE_MANIPULATION, false).pipe(
                        filter((flag) => flag !== undefined)
                    ).subscribe((value) => {
                        this.pageManipulationFF = value;
                    });

                    this.FeatureFlags.getFlag(FEATURE_FLAGS.NDV_COPY_TEXT, false).pipe(
                        filter((flag) => flag !== undefined)
                    ).subscribe((value) => {
                        this.canCopyText = value;
                    });

                    this.FeatureFlags.getFlag(FEATURE_FLAGS.NDV_STRIKEOUT_TEXT, false).pipe(
                        filter((flag) => flag !== undefined)
                    ).subscribe((value) => {
                        this.canStrikeoutText = value;
                    });

                    this.modifyURLForPendo(this.doc.subType);

                    this.loadingDoc = false;
                },
                (error) => {
                    if (error.status === 403 || error.status === 404) {
                        setTimeout(() => {
                            window.history.back();
                        }, 2000);
                    }
                }
            );
    }

    private watchForFormStatusChanges(): void {
        if (this.doc.formStatus !== 'checking-form') {
            return;
        }

        interval(this.formStatusCheckInterval)
            .pipe(
                takeUntil(
                    merge(this.formStatusResolved$, this.destroy$)
                ),
                switchMap(() => this.Documents.poll(
                    this.stateParams.documentId,
                    Number(this.stateParams.version) || this.currentDisplayVersion
                )),
                tap((doc) => {
                    if (doc.formStatus !== 'checking-form') {
                        this.doc.formStatus = doc.formStatus;
                        this.formStatusResolved$.next();
                        this.formStatusResolved$.complete();
                        this.$state.reload();
                    }
                })
            ).subscribe();
    }

    modifyURLForPendo(type: string): void {
        const currentPath = this.$window.location?.href;
        const adjustPathForDocType = function determinePendoPath() {
            const newPath = type !== 'content'
                ? currentPath.replace('/documents/', `/${type}s/`)
                : currentPath;
            return newPath;
        };
        // ideally, this should be a service
        // if you're clonning, please don't just c/p
        this.$window.pendo?.location?.addTransforms([{
            attr: 'href',
            action: 'Replace',
            data: function transform() {
                return adjustPathForDocType();
            }
        }]);
    }

    documentViewerHandleError(message: string): void {
        if (typeof message !== 'string') {
            return;
        }
        if (message) {
            this.Notifications.error(message);
        }
        else {
            this.Notifications.unexpectedError();
        }
        Sentry.captureMessage(`
            [ebinder-client:Apryse-message]:
            D ID: ${this.doc.id}, extension: ${this.doc.ext}, U ID: ${this.currentUser?.email}, T ID: ${this.doc.teamId}, Error: ${JSON.stringify(message, null, 2)}
        `, Sentry.Severity.Fatal);

        Sentry.captureEvent({
            message: `[ebinder-client:Apryse-event] ${message}`,
            level: Sentry.Severity.Fatal,
            extra: {
                document: {
                    id: this.doc.id,
                    version: this.doc.version,
                    ext: this.doc.ext,
                    pageCount: this.doc.pageCount,
                    teamId: this.doc.teamId
                },
                user: this.currentUser
            }
        });
    }

    getDocRequestVersion(doc: Document, explicitVersion: number): number | string {
        const docVersion = typeof doc.id !== 'string' && 'version' in doc.id ? doc.id.version : doc.version;

        if (doc.subType === 'shortcut' || docVersion === 0 || docVersion === 0) {
            return 0;
        }

        return explicitVersion || docVersion;
    }

    private getNavParams(doc: Document, version: number): NavParams {
        const requestVersion = Number(this.getDocRequestVersion(doc, version));
        const documentId = typeof doc.id === 'string' ? doc.id : doc.id.documentId;

        const navParams: NavParams = {
            teamId: doc.teamId,
            documentId,
            version: requestVersion,
            contentVersion: version && version !== requestVersion ? version : null
        };

        return navParams;
    }

    saveForm(formSaveData: FormSaveEvent) {
        const {
            formFields,
            signaturesXfdfString,
            onSuccessfulSave,
            canFinalizeForm,
            numberOfSignaturesAddedToTheForm,
            hasToFinalizeForm
        } = formSaveData;

        if (numberOfSignaturesAddedToTheForm > 0 && this.jobTitleRequired && !this.currentUser.profile.jobTitle) {
            const jobTitleRequiredModal = this.Modals.show(JobTitleRequiredComponent, {
                class: 'modal-md',
                initialState: {}
            });

            jobTitleRequiredModal.content.onUpdateProfile.subscribe((event: JobTitleRequiredEvent) => {
                event.onUpdateProfile();
            });

            jobTitleRequiredModal.content.dismiss.subscribe(() => {
                jobTitleRequiredModal.hide();
            });

            return;
        }

        this.checkRemoteUserMissingSignPin(numberOfSignaturesAddedToTheForm > 0)
            .subscribe((missingPin) => {
                if (missingPin) {
                    return this.showMissingSignPinModal();
                }

                const jobTitle = this.jobTitleRequired && numberOfSignaturesAddedToTheForm > 0 ? this.currentUser.profile.jobTitle : '';

                const formSaveModal = this.Modals.show(NdvFormSaveComponent, {
                    class: 'modal-md',
                    initialState: {
                        jobTitle,
                        entity: this.doc,
                        canFinalizeForm,
                        mustFinalize: hasToFinalizeForm,
                        signatureCount: numberOfSignaturesAddedToTheForm
                    }
                });

                formSaveModal.content.onSubmit.subscribe(async (saveFormEvent: SaveFormModalEvent) => {
                    const {
                        shouldFinalize,
                        reason,
                        email,
                        password,
                        signingPasscode
                    } = saveFormEvent;

                    const updateFormParams = {
                        formFields,
                        signaturesXfdfString,
                        teamId: this.currentTeam.id,
                        documentId: this.doc.id as string,
                        shouldFinalize,
                        documentVersion: this.doc.version,
                        reason: reason || '',
                        email,
                        password,
                        signingPasscode
                    };

                    let preServiceCallMessage = 'Saving draft, this may take a minute.';
                    if (shouldFinalize) {
                        preServiceCallMessage = 'Finalizing document, this may take a minute.';
                    }
                    setTimeout(() => this.Notifications.info(preServiceCallMessage), 500);

                    this.DocumentForms.updateForm(updateFormParams)
                        .pipe(take(1))
                        .subscribe(
                            () => {
                                onSuccessfulSave();

                                let successMessage = 'Document annotated and draft saved successfully.';
                                if (shouldFinalize) {
                                    successMessage = 'Document annotated and finalized successfully.';

                                    if (numberOfSignaturesAddedToTheForm > 0) {
                                        successMessage = 'Document signed, annotated and finalized successfully.';
                                    }
                                    else if (numberOfSignaturesAddedToTheForm === 0 && hasToFinalizeForm) {
                                        successMessage = 'Document finalized successfully.';
                                    }
                                }

                                this.Notifications.success(successMessage);
                                saveFormEvent.onSuccess();

                                const navParams = this.getNavParams(this.doc, Number(this.doc.version + 1));
                                this.$state.go('app.team.document-show', navParams, {});
                                this.promptNextDocumentToSign(numberOfSignaturesAddedToTheForm > 0);
                            },
                            (err) => {
                                saveFormEvent.onError();
                                if (err.error && err.error.message) {
                                    this.Notifications.error(err.error.message);
                                }
                                else {
                                    this.Notifications.unexpectedError();
                                }
                            }
                        );
                });
            });
    }

    clearAll(event: ClearAllEvent) {
        const modalRef = this.Modals.show(AnnotationsClearAllModalComponent, {
            initialState: {
                isForm: event.isForm
            }
        });

        modalRef.content.dismiss.subscribe(() => {
            modalRef.hide();
        });

        modalRef.content.clearAll.subscribe(() => {
            event.onClearAllConfirm();
            modalRef.hide();
        });
    }

    saveAnnotationChanges(saveAnnotationsData: AnnotationSaveEvent) {
        if (
            saveAnnotationsData.typesOfAnnotations.length === 0
            && Object.values(saveAnnotationsData.pageManipulationActions).every((value) => value === false)
        ) {
            return;
        }

        const annotations = saveAnnotationsData.typesOfAnnotations.map((annotationType) => ({ type: annotationType }));

        this.checkRemoteUserMissingSignPin(saveAnnotationsData.numberOfSignaturesAdded > 0)
            .subscribe((missingPin) => {
                if (missingPin) {
                    return this.showMissingSignPinModal();
                }

                const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';

                const annotationsModal = this.Modals.show(AnnotationsModalComponent, {
                    class: 'modal-md',
                    initialState: {
                        doc: this.doc,
                        annotations,
                        jobTitle,
                        pages: saveAnnotationsData.pages,
                        pageManipulationActions: saveAnnotationsData.pageManipulationActions,
                        numberOfSignaturesAdded: saveAnnotationsData.numberOfSignaturesAdded
                    }
                });

                annotationsModal.content.save.subscribe((event: CreateAnnotationsEvent) => {
                    const { data, onSuccess, onError } = event;
                    const createAnnotationsParams = {
                        xfdfString: saveAnnotationsData.xfdfString,
                        teamId: this.currentTeam.id,
                        documentId: this.doc.id as string,
                        documentVersion: this.doc.version,
                        reason: data.reason,
                        signingReason: data.signingReason,
                        email: data.email,
                        password: data.password,
                        signingPasscode: data.signingPasscode,
                        pages: data.pages,
                        pageManipulationReason: data.pageManipulationReason
                    };

                    this.DocumentAnnotations.createAnnotations(createAnnotationsParams)
                        .pipe(take(1))
                        .subscribe(
                            () => {
                                onSuccess();
                                saveAnnotationsData.onSuccessfulSave();

                                const hasPageManipulationActions = Object.values(saveAnnotationsData.pageManipulationActions)
                                    .some((value) => value === true);

                                const hasAnnotationActions = annotations.some((annotation) => annotation.type !== 'signature');
                                const hasSignatureActions = annotations.some((annotation) => annotation.type === 'signature');

                                const messages = {
                                    111: 'Document edited, annotated and signed successfully.',
                                    110: 'Document edited and annotated successfully.',
                                    101: 'Document edited and signed successfully.',
                                    '011': 'Document annotated and signed successfully.',
                                    '001': 'Document signed successfully.',
                                    '010': 'Document annotated successfully.',
                                    100: 'Document edited successfully.'
                                };

                                const key = `${+hasPageManipulationActions}${+hasAnnotationActions}${+hasSignatureActions}`;
                                const notificationMessage = messages[key] || 'No actions performed';

                                this.Notifications.success(notificationMessage);

                                const navParams = this.getNavParams(this.doc, Number(this.doc.version + 1));
                                this.$state.go('app.team.document-show', navParams, {});
                                this.promptNextDocumentToSign(saveAnnotationsData.numberOfSignaturesAdded > 0);
                            },
                            (err) => {
                                onError();
                                if (err.error && err.error.message) {
                                    this.Notifications.error(err.error.message);
                                }
                                else {
                                    this.Notifications.unexpectedError();
                                }
                            }
                        );
                });

                annotationsModal.content.dismiss.subscribe(() => {
                    annotationsModal.hide();
                });
            });
    }


    ngOnDestroy(): void {
        if (this.editDetailsModal) {
            this.editDetailsModal.hide();
        }
        this.destroy$.next();
        this.destroy$.complete();
        this.formStatusResolved$.complete();
    }

    onQcReviewUpdate(): void {
        this.loadDocument();
    }

    setVersion(version: number): void {
        this.Documents.goToDocument({
            doc: this.doc,
            version
        });
    }

    acceptQcReview(event: QcAcceptEvent) {
        const params = {
            documentId: event.docId,
            documentVersion: event.docVersion,
            teamId: this.currentTeam.id
        };

        this.QCReviews.acceptQcReview(params)
            .pipe(take(1))
            .subscribe(
                (qcReview) => {
                    const successMessage = `QC Review for version ${qcReview.documentVersion} successfully started`;
                    event.onSuccess();
                    this.Notifications.success(successMessage);
                },
                (err) => {
                    event.onError();
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                },
                () => {
                    this.loadDocument();
                }
            );
    }

    approveQcReview(event: ApproveReviewEvent) {
        const approveParams = {
            documentId: this.doc.id as string,
            documentVersion: this.doc.version,
            teamId: this.currentTeam.id,
            comment: event.comment
        };

        this.QCReviews.approveQcReview(approveParams)
            .pipe(take(1))
            .subscribe(
                (qcReview) => {
                    const successMessage = `QC Review for version ${qcReview.documentVersion} successfully approved`;
                    this.Notifications.success(successMessage);
                    event.onSuccess();
                },
                (err) => {
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    event.onError();
                },
                () => {
                    this.loadDocument();
                }
            );
    }

    strikeThroguhLogRows(event: StrikeThroughLogEntriesEvent) {
        const params = {
            documentId: this.doc.id,
            teamId: this.currentTeam.id,
            logEntryIds: event.logEntryIds,
            reason: event.reason
        };

        this.LogEntries.strikeThroughLogEntries(params).subscribe(
            (result) => {
                let successes = 0;
                let failures = 0;
                result.filter((item) => {
                    if (item.statusCode === 200) {
                        successes += 1;
                        return true;
                    }

                    failures += 1;
                    return false;
                });

                if (successes) {
                    const rows = successes === 1 ? 'row' : 'rows';
                    this.Notifications.success(`${successes} Log ${rows} struckthrough successfully.`);
                }
                if (failures) {
                    const rows = failures === 1 ? 'row' : 'rows';
                    this.Notifications.error(`${failures} Log ${rows} not struckthrough.`);
                }
                event.onSuccess();
                this.afterLogEntriesSigned(true);
            },
            (error) => {
                if (error.error && error.error.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
                event.onError();
            }
        );
    }

    rejectQcReview(event: RejectReviewEvent) {
        const rejectParams = {
            documentId: this.doc.id as string,
            documentVersion: this.doc.version,
            teamId: this.currentTeam.id,
            comment: event.comment,
            reasons: event.reasons
        };

        this.QCReviews.rejectQcReview(rejectParams)
            .pipe(take(1))
            .subscribe(
                (qcReview) => {
                    const successMessage = `QC Review for version ${qcReview.documentVersion} successfully rejected`;
                    this.Notifications.success(successMessage);
                    event.onSuccess();
                },
                (err) => {
                    if (err.error && err.error.message) {
                        this.Notifications.error(err.error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    event.onError();
                },
                () => {
                    this.loadDocument();
                }
            );
    }

    private qcReviewDecline(params: QcReviewDeclineParam, event: QcDeclineEvent): Observable<QcReview | null> {
        return this.QCReviews.declineQcReview(params).pipe(
            tap((qcReview) => {
                const successMessage = `QC Review for version ${qcReview.documentVersion} successfully declined`;
                this.Notifications.success(successMessage);
            },
            (error) => {
                event.onError();
                if (error.error && error.error.message) {
                    this.Notifications.error(error.error.message);
                }
                else {
                    this.Notifications.unexpectedError();
                }
                return of(null);
            },
            () => {
                this.loadDocument();
            })
        );
    }

    declineQcReview(event: QcDeclineEvent): void {
        const docVersion = this.doc.version;

        const modalRef = this.Modals.show(DocumentDeclineQcReviewComponent, {
            initialState: {}
        });

        const declineParams = {
            documentId: this.doc.id as string,
            documentVersion: docVersion,
            teamId: this.currentTeam.id
        };

        modalRef.content.close.subscribe(() => {
            event.onClose();
        });

        modalRef.content.decline.pipe(
            switchMap((resp) => this.qcReviewDecline({ ...declineParams, reason: resp.reason }, event))
        ).subscribe(() => {
            modalRef.hide();
        }, () => {
            modalRef.hide();
        });
    }

    private reloadDocument(): void {
        // no longer reload through ui router, just the data is reloaded
        this.loadDocument();
        this.loadSignatureQueue().subscribe();
        this.loadTaskQueue();
        this.loadTrainingQueueIfTeamHasTrainingGate();
    }

    private loadDocument(): void {
        const { version } = this.stateParams;
        const contentVersion = this.stateParams.contentVersion || version;

        this.Documents
            .load(this.stateParams.documentId, version, contentVersion)
            .subscribe((doc) => {
                this.doc = doc;
                this.checkPendingSignatures();
            });
    }

    private getDocumentId(): string {
        return typeof this.doc.id !== 'string' ? this.doc.id.documentId : this.doc.id;
    }

    private loadSignatureQueue(includeRequestId = true): Observable<ActionBarConfig> {
        this.loadingSignatureQueue = true;
        const documentId = this.getDocumentId();

        return this.ActionBar.getSignatureQueue({
            teamId: this.stateParams.teamId,
            documentId,
            documentType: this.doc.subType,
            ...includeRequestId && { signatureRequestId: this.stateParams.signatureRequestId }
        }).pipe(tap({
            next: (queue) => {
                this.signatureQueue = queue;
            },
            error: () => {
                this.signatureQueue = undefined;
            },
            complete: () => {
                this.loadingSignatureQueue = false;
            }
        }));
    }

    private loadTaskQueue(): void {
        this.loadingTaskQueue = true;
        const { taskId } = this.stateParams;
        const documentId = this.getDocumentId();
        this.ActionBar
            .getTaskQueue({
                teamId: this.stateParams.teamId,
                documentId,
                taskId
            })
            .subscribe((queue) => {
                this.taskQueue = queue;
                this.loadingTaskQueue = false;
            }, () => {
                this.taskQueue = undefined;
                this.loadingTaskQueue = false;
            });
    }

    private loadTrainingQueueIfTeamHasTrainingGate(): void {
        const hasTrainingGate = this.currentTeam.settings.features.trainingGate;
        if (hasTrainingGate) {
            this.loadTrainingQueue();
        }
    }

    private loadTrainingQueue(): void {
        this.loadingTrainingQueue = true;

        this.ActionBar.getTrainingQueueActionBarConfig$().pipe(
            tap((trainingActionBarConfig) => {
                this.trainingQueue = trainingActionBarConfig;
                this.loadingTrainingQueue = false;
            }),
            catchError((error: HttpErrorResponse) => {
                this.Notifications.error(USER_TRAINING_ERRORS.LOAD_TRAINING_QUEUE_ERROR);
                this.loadingTrainingQueue = false;
                return throwError(error);
            }),
            take(1)
        ).subscribe();
    }

    private openDetailsIfMissing(): void {
        const details = this.doc
            && this.doc.permissions.updateLogDetails
            && this.doc.logDetails;

        if (this.logDetailsMissing(details) && this.doc.version === 1) {
            this.openEditDetails();
        }
    }

    private logDetailsMissing(details: LogDetail[]): boolean {
        const mutableDetails = details && details.length && details
            .filter(({ immutable }) => !immutable);

        return this.doc.isLatestVersion
            && mutableDetails && mutableDetails.length
            && mutableDetails.every(({ value }) => !value);
    }

    private getMonitorReviews(): void {
        if (!this.doc.permissions.documentViewMonitorReview) {
            this.monitorReviewsEnabled = false;
            return;
        }

        const documentId: string = this.doc.id as string;
        this.MonitorReviews.checkMonitorReviewEnabled(documentId)
            .pipe(
                switchMap((isEnabled) => {
                    this.monitorReviewsEnabled = isEnabled;
                    if (!isEnabled) {
                        return of([]);
                    }
                    return this.MonitorReviews.getReviewsForDoc(documentId);
                })
            )
            .subscribe(
                (reviews) => {
                    this.monitorReviews = reviews;
                    const { monitorReviewId } = this.stateParams;
                    if (monitorReviewId) {
                        this.monitorReview = this.monitorReviews.find((review) => review.id === monitorReviewId);
                    }
                },
                () => {
                    this.monitorReviews = [];
                }
            );
    }

    onMonitorReviewsUpdate(event: MonitorReviewsUpdateEvent): void {
        this.monitorReviews = _.cloneDeep(event.monitorReviews);
    }

    openAuditTrailModal(auditData): void {
        const {
            subject = AuditTrailSubject.DOCUMENT,
            ...auditItem
        } = auditData || this.doc;

        const params = {
            subject,
            teamId: auditItem.teamId,
            objectId: auditItem.id,
            limitToOverwritten: false,
            ...this.AuditTrail.auditPagination
        };

        this.AuditTrail.getAudits(params).subscribe(
            (audits) => {
                this.Modals.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    initialState: {
                        data: audits,
                        item: auditItem,
                        subject: params.subject,
                        pagination: this.AuditTrail.auditPagination,
                        onPageChange: this.AuditTrail.getAudits.bind(this.AuditTrail)
                    }
                });
            },
            (error) => {
                this.ApiError.handleError(error);
            }
        );
    }

    openViewDocumentSharingHistoryModal() {

        const params: GetDocumentHistoriesPathParams = {
            document: {
                id: this.doc.id as string,
                version: this.doc.version
            },
            exchangeEventId: this.doc.exchangeEventId
        };

        this.Modals.show(DocumentHistoryModalComponent, {
            class: 'modal-lg',
            initialState: {
                document: this.doc,
                getDocumentHistories: (paginationParams: GetDocumentHistoriesQueryParams) => this.DocumentHistory
                    .getDocumentHistories({ ...params, ...paginationParams })
            }
        });
    }


    requestDownload(): void {

        const modalRef = this.Modals.show(DocumentDownloadComponent, {
            class: 'modal-md',
            initialState: {
                options: {
                    teamId: this.doc.teamId,
                    binderId: this.doc.binderId,
                    version: this.doc.version,
                    contentVersion: this.currentDisplayVersion,
                    documents: [this.doc]
                },
                team: this.currentTeam
            }
        });

        modalRef.content.download.subscribe((params) => {
            this.Downloads
                .downloadFile(params)
                .subscribe(
                    () => {
                        const href = this.$state.href('app.team.downloads', { teamId: this.doc.teamId });
                        const message = `<p>Starting download now! We'll notify you when your download is ready.</p>
                        Go to <a class="page-action u-d-inline u-font-weight-bold" href=${href}>MY DOWNLOADS</a> to view all downloads.`;
                        this.Notifications.info(message);
                    },
                    (error) => {
                        const message = (error && error.error && error.error.message);
                        message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
                    }
                );
        });
    }

    private checkPendingSignatures(): void {
        const currentTeam = this.CurrentSession.getCurrentTeam();
        const currentUser = this.CurrentSession.getCurrentUser();

        this.pendingSignatureRequest = this.doc.pendingSignatures.find((req) => req.userId === currentUser.id && !req.entryId);
        const teamHasPendingSignaturesRestriction = _.get(currentTeam, 'settings.signatures.pendingRestriction', false);

        this.requestIsPastDue = this.pendingSignatureRequest && this.pendingSignatureRequest.isDue;
        this.teamSignatureRestricted = !this.pendingSignatureRequest
            && teamHasPendingSignaturesRestriction
            && this.doc.pendingSignatures.length > 0;
        this.checkingSignatures = false;
    }


    checkAndSetJobTitle() {
        if (this.jobTitleRequired && !this.currentUser.profile.jobTitle) {

            const jobTitleRequiredModal = this.Modals.show(JobTitleRequiredComponent, {
                class: 'modal-md',
                initialState: {}
            });

            jobTitleRequiredModal.content.onUpdateProfile.subscribe((event: JobTitleRequiredEvent) => {
                event.onUpdateProfile();
                this.$state.go('app.user-profile');
            });

            jobTitleRequiredModal.content.dismiss.subscribe(() => {
                jobTitleRequiredModal.hide();
            });
        }
    }

    signDocument() {
        if (this.isSigning) {
            return;
        }

        this.checkRemoteUserMissingSignPin(true).subscribe((missingPin) => {
            if (missingPin) {
                return this.showMissingSignPinModal();
            }
            this.openDocSigningForm(this.doc, this.pendingSignatureRequest);
        });

    }

    openDocSigningForm(doc: Document, signatureRequest?: SignatureRequest) {
        this.checkAndSetJobTitle();

        const pendingSignature = signatureRequest && (signatureRequest.objectId === doc.id ? signatureRequest : null);
        const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';

        const signDocumentModal = this.Modals.show(SignDocumentComponent, {
            class: 'modal-md',
            initialState: {
                doc,
                currentUser: this.currentUser,
                currentTeam: this.currentTeam,
                pendingSignature,
                jobTitle
            }
        });

        signDocumentModal.content.dismiss.subscribe((closeReason: SignDocumentCloseReason) => {
            this.promptNextDocumentToSign(closeReason === 'signed');
            this.isSigning = false;
            signDocumentModal.hide();
        });

        signDocumentModal.content.save.subscribe((event: SignDocumentSaveEvent) => {
            this.isSigning = true;

            const { data, onSuccess, onError } = event;

            this.saveDocumentSignature(data).subscribe(
                () => {
                    onSuccess();
                    this.isSigning = false;
                },
                () => {
                    onError();
                    this.isSigning = false;
                }
            );
        });
    }

    declineDocumentSignature() {
        const declineSignatureModal = this.Modals.show(DeclineDocumentComponent, {
            class: 'modal-md',
            initialState: {
                currentUser: this.currentUser,
                currentTeam: this.currentTeam,
                doc: this.doc,
                pendingSignature: this.pendingSignatureRequest
            }
        });

        declineSignatureModal.content.onDecline.subscribe((data: DocumentDeclineEventEmitter) => {
            this.checkingSignatures = true;
            this.saveDocumentSignature(data.signature).subscribe(
                () => {
                    this.loadDocument();
                    data.onSuccess();
                },
                () => {
                    data.onError();
                    this.checkingSignatures = false;
                }
            );
        });
    }

    signLogEntries(event: SignLogEntriesEvent) {

        this.checkRemoteUserMissingSignPin(true).subscribe((missingPin) => {
            if (missingPin) {
                return this.showMissingSignPinModal();
            }

            if (event.length === 1) {
                return this.signSignleLogEntry(this.doc, null, event[0].logEntry, event[0].column);
            }
            return this.openBulkLogEntriesSignForm(this.doc, event);
        });
    }

    signSignleLogEntry(
        doc: Document,
        signatureRequest?: SignatureRequest,
        logEntry?: LogEntry,
        column?: string
    ) {
        this.checkAndSetJobTitle();

        const pendingSignature = signatureRequest && (signatureRequest.objectId === doc.id ? signatureRequest : null);
        const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';

        const signDocumentModal = this.Modals.show(SignDocumentComponent, {
            class: 'modal-md',
            initialState: {
                doc,
                currentUser: this.currentUser,
                currentTeam: this.currentTeam,
                pendingSignature,
                logEntry,
                selectedColumnName: column,
                jobTitle
            }
        });

        signDocumentModal.content.dismiss.subscribe((closeReason: SignDocumentCloseReason) => {
            this.promptNextDocumentToSign(closeReason === 'signed');
            signDocumentModal.hide();
        });

        signDocumentModal.content.save.subscribe((event: SignDocumentSaveEvent) => {
            const { data, onSuccess, onError } = event;

            this.saveLogEntrySignatures(
                this.convertSingleToBulkEntrySign(data)
            ).subscribe(
                (result) => {
                    if (result.successes) {
                        const rows = result.successes === 1 ? 'row' : 'rows';
                        this.Notifications.success(`${result.successes} Log ${rows} ${result.status} successfully.`);
                    }
                    if (result.failures) {
                        const rows = result.failures === 1 ? 'row' : 'rows';
                        this.Notifications.error(`${result.failures} Log ${rows} not ${result.status}.`);
                    }
                    onSuccess();
                    this.afterLogEntriesSigned(true);
                },
                () => {
                    onError();
                }
            );
        });
    }

    declineLogEntrySignatures(logEntryData: DeclineLogEntryEvent) {
        const pendingSignature: SignatureRequest = logEntryData.logEntry.columns.find((column) => {
            return column.name === logEntryData.column;
        }).signatureRequest;

        const declineSignatureModal = this.Modals.show(DeclineDocumentComponent, {
            class: 'modal-md',
            initialState: {
                currentUser: this.currentUser,
                currentTeam: this.currentTeam,
                doc: this.doc,
                logEntry: logEntryData && logEntryData.logEntry,
                columnName: logEntryData && logEntryData.column,
                pendingSignature
            }
        });

        declineSignatureModal.content.onDecline.subscribe((data: DocumentDeclineEventEmitter) => {
            const params = this.convertSingleToBulkDeclineEntry(data.signature);
            this.saveLogEntrySignatures(params).subscribe(
                (result: SignLogEntriesResponse) => {
                    data.onSuccess();
                    if (result) {
                        if (result.successes) {
                            const rows = result.successes === 1 ? 'row' : 'rows';
                            this.Notifications.success(`${result.successes} Log ${rows} ${result.status} successfully.`);
                        }
                        if (result.failures) {
                            const rows = result.failures === 1 ? 'row' : 'rows';
                            this.Notifications.error(`${result.failures} Log ${rows} not ${result.status}.`);
                        }
                        const reload = true;
                        this.afterLogEntriesSigned(reload);
                    }
                },
                () => {
                    data.onError();
                }
            );
        });
    }

    private openBulkLogEntriesSignForm(doc: Document, signatures: SignLogEntriesEvent) {
        this.checkAndSetJobTitle();

        const jobTitle = this.jobTitleRequired ? this.currentUser.profile.jobTitle : '';

        const signLogEntriesModal = this.Modals.show(SignLogEntriesComponent, {
            class: 'modal-md',
            initialState: {
                currentUser: this.currentUser,
                currentTeam: this.currentTeam,
                doc,
                logEntrySignatures: signatures,
                jobTitle
            }
        });

        signLogEntriesModal.content.dismiss.subscribe((closeReason: SignLogEntriesCloseReason) => {
            this.promptNextDocumentToSign(closeReason === 'signed');
            signLogEntriesModal.hide();
        });

        signLogEntriesModal.content.save.subscribe((event: SignLogEntriesSaveEvent) => {
            const { data, onSuccess, onError } = event;

            this.saveLogEntrySignatures(data).subscribe(
                (result) => {
                    if (result.successes) {
                        const rows = result.successes === 1 ? 'row' : 'rows';
                        this.Notifications.success(`${result.successes} Log ${rows} ${result.status} successfully.`);
                    }
                    if (result.failures) {
                        const rows = result.failures === 1 ? 'row' : 'rows';
                        this.Notifications.error(`${result.failures} Log ${rows} not ${result.status}.`);
                    }
                    this.afterLogEntriesSigned(true);

                    if (result.successes) {
                        onSuccess();
                    }

                    if (!result.successes && result.failures) {
                        onError();
                    }
                },
                () => {
                    onError();
                }
            );
        });
    }

    private checkRemoteUserMissingSignPin(hasSignature: boolean): Observable<boolean> {
        const requiresPin = this.currentUser.isRemote && hasSignature;
        if (!requiresPin) {
            return of(false);
        }

        return this.Users.getCurrent().pipe(
            map((latestUser) => {
                this.currentUser = latestUser;
                this.CurrentSession.setCurrentUser(latestUser);
                return !latestUser.hasSigningPasscode;
            })
        );
    }

    private showMissingSignPinModal(): void {
        const missingPasscodeModal = this.Modals.show(MissingSigningPasscodeComponent, {
            class: 'modal-md',
            initialState: {
                entity: this.doc
            }
        });

        missingPasscodeModal.content.dismiss.subscribe(() => {
            missingPasscodeModal.hide();
            this.isSigning = false;
        });

        missingPasscodeModal.content.onSubmit.subscribe((event: MissingSigningPasscodeSubmitEvent) => {
            const { onSuccess, onError } = event;

            this.SigningPasscode.resetSigningPasscode().subscribe(
                () => {
                    this.Notifications.success('Instructions for setting your Signing PIN has been sent to your email');
                    onSuccess();
                },
                () => {
                    onError();
                }
            );
        });
    }

    private afterLogEntriesSigned(reload = false): void {
        if (this.doc.logStatus === 'finalized') {
            this.setVersion(this.doc.version + 1);
        }
        else {
            this.Documents.goToDocument({
                doc: this.doc,
                reload
            });
        }
    }

    private saveLogEntrySignatures(signatures: BulkSignOrDeclineBody): Observable<SignLogEntriesResponse> {
        return this.LogEntries.signLogEntries(this.doc.id as string, signatures);
    }

    private saveDocumentSignature(signature: DocumentSignData | DocumentDeclineData): Observable<void | HttpErrorResponse> {
        const contentVersion = this.Documents.getCurrentDocDisplayVersion(this.doc);

        return this.Documents.signDocument(this.doc, signature, contentVersion)
            .pipe(take(1), tap(() => {
                this.reloadDocument();
            }));
    }

    private convertSingleToBulkEntrySign(signature: DocumentSignData): BulkSignBody {
        return {
            signatures: [{
                logEntryId: signature.logEntryId,
                column: signature.column,
                reason: signature.reason,
                jobTitle: signature.jobTitle
            }],
            teamId: signature.teamId,
            status: signature.status,
            email: signature.email,
            password: signature.password,
            signingPasscode: signature.signingPasscode
        };
    }

    private convertSingleToBulkDeclineEntry(signature: DocumentDeclineData): BulkSignOrDeclineBody {
        return {
            signatures: [{
                logEntryId: signature.logEntryId,
                column: signature.column,
                reason: signature.reason
            }],
            teamId: signature.teamId,
            status: signature.status,
            email: signature.email,
            signatureComment: signature.signatureComment
        };
    }

    private promptNextDocumentToSign(wasSigned: boolean): void {
        if (!wasSigned) {
            return;
        }

        this.Modals.show(SignatureCompleteComponent, {
            class: 'modal-md',
            initialState: {
                actionOptions: this.signatureQueue
            }
        });
    }

    private getStudies(): void {
        const { teamId } = this.doc;
        const documentId = this.getDocumentId();
        this.Studies.getDocumentStudyProfile(teamId, documentId).subscribe({
            next: (value) => {
                this.studies = value;
                if (value && value.length === 1) {
                    this.hasUnviewableStudies = false;
                    this.viewableStudy.next(value[0]);
                }
                else {
                    this.hasUnviewableStudies = value && value.length > 1;
                    this.viewableStudy.next(undefined);
                }
            },
            error: (err) => {
                if (err && err.status === 403) {
                    this.hasUnviewableStudies = true;
                    this.viewableStudy.next(undefined);
                    return;
                }
                this.hasUnviewableStudies = false;
                this.viewableStudy.next(undefined);
                throw err;
            }
        });
    }

    onDocumentRename({ document }): void {
        this.doc = {
            ...this.doc,
            name: document.name,
            title: document.title,
            titleCanonical: document.titleCanonical,
            filename: document.filename,
            filenameCanonical: document.filenameCanonical
        };
        this.crumbs = this.getCrumbs();
    }

    openTask(taskToOpen: Task | undefined): void {
        forkJoin({
            task: taskToOpen ? this.Teams.getTask(this.doc.teamId, taskToOpen.id) : of(null),
            users: this.Teams.getTaskUsers(this.doc.teamId, this.doc.id.toString(), this.doc.type)
        }).subscribe((data) => {
            const updateTaskModal = this.Modals.show(TaskFormComponent, {
                class: 'modal-md',
                initialState: {
                    task: taskToOpen ? data.task : null,
                    doc: this.doc,
                    users: data.users,
                    isCreate: !taskToOpen
                }
            });
            updateTaskModal.content.onSave.subscribe((event: TaskSaveEvent) => {
                const { task } = event;
                if (taskToOpen) {
                    this.Teams.updateTask(this.doc.teamId.toString(), task)
                        .subscribe(
                            () => {
                                event.onSuccess();
                                this.loadDocument();
                            },
                            () => {
                                event.onError();
                            }
                        );
                }
                else {
                    this.Teams.createTask(this.doc.teamId.toString(), task)
                        .subscribe(
                            () => {
                                event.onSuccess();
                                this.loadDocument();
                            },
                            () => {
                                event.onError();
                            }
                        );
                }

            });
            updateTaskModal.content.onDestroy.subscribe((event: TaskDestroyEvent) => {
                const { task } = event;
                this.Teams.deleteTask(this.doc.teamId, task)
                    .subscribe(
                        () => {
                            event.onSuccess();
                            this.loadDocument();
                        },
                        () => {
                            event.onError();
                        }
                    );
            });
        });
    }

    openEditDetails(): void {
        this.editDetailsModal = this.Modals.show(DocumentEditDetailsComponent, {
            initialState: {
                doc: this.doc
            }
        });
        this.editDetailsModal.content.save
            .pipe(take(1))
            .subscribe((editEvent: EditDetailsEvent): void => {
                this.saveLogDetails(editEvent);
            });
    }

    private saveLogDetails(saveData: EditDetailsEvent): void {
        const { data, onSuccess, onError } = saveData;
        const { logDetails, reason } = data;
        this.Documents.updateLogMetadata(this.doc.id as string, { logDetails, reason })
            .pipe(take(1))
            .subscribe((updatedDoc: Document) => {
                onSuccess();

                const documentId = updatedDoc.id as DocumentId;
                if (documentId.version > this.doc.versions.length) {
                    this.Notifications.success('Log version automatically updated');
                }

                if (this.logDetailsMissing(this.doc.logDetails)) {
                    this.doc.logDetails = updatedDoc.logDetails;
                }

                this.Documents.goToDocument({ doc: updatedDoc });

            }, onError);
    }

    openEditLogLegend(): void {
        this.openEditLogField('legend');
    }

    openEditLogInformation(): void {
        this.openEditLogField('information');
    }

    openEditDoaLogRolesResponsibilitiesModal(): void {
        this.StudyRoles.setStudyRolesList$.pipe(take(1)).subscribe();

        const editRolesResponsibilitiesModalRef = this.Modals.show(DoaLogTemplateRolesResponsibilitiesEditComponent, {
            class: 'modal-lg',
            initialState: {
                existingStudyResponsibilities: { ...this.doc.responsibilities },
                existingStudyRoles: [...this.doc.roles],
                studyRolesList$: this.StudyRoles.studyRolesList$,
                studyRolesMap$: this.StudyRoles.studyRolesMap$,
                isLoadingStudyRoles$: this.StudyRoles.isLoadingStudyRoles$,
                isLogDocument: true
            }
        });

        editRolesResponsibilitiesModalRef.content.rolesResponsibilitiesEdited.pipe(
            switchMap(({ data, onSuccess, onError }) => (
                this.Documents.addDoaLogRolesResponsibilities(
                    this.doc.id as string,
                    {
                        responsibilities: data.studyResponsibilities.responsibilities,
                        roles: data.studyRoles.roles,
                        reason: data.studyRoles.reason
                    }
                ).pipe(
                    tap(() => {
                        this.setVersion(this.doc.version + 1);
                        onSuccess();
                    }),
                    catchError(() => {
                        onError();
                        return of(null);
                    })
                )
            )),
            take(1)
        ).subscribe();
    }

    private openEditLogField(field: 'legend' | 'information'): void {

        const logEditMetadataModal = this.Modals.show(LogEditMetadataComponent, {
            class: 'modal-md',
            initialState: {
                metadataType: field,
                document: this.doc
            }
        });

        logEditMetadataModal.content.dismiss.subscribe(() => {
            logEditMetadataModal.hide();
        });

        logEditMetadataModal.content.save.subscribe((event: LogEditMetadataEvent) => {
            const { data, onSuccess, onError } = event;
            this.Documents.updateLogMetadata(this.doc.id as string, data)
                .pipe(take(1)).subscribe(
                    (doc: Document) => {
                        onSuccess();
                        this.Documents.goToDocument({ doc });
                        const documentId = doc.id;
                        if (typeof documentId !== 'string' && documentId.version > this.doc.versions.length) {
                            this.Notifications.success('Log version automatically updated');
                        }
                    },
                    () => {
                        onError();
                    }
                );
        });
    }

    replaceDocument(): void {

        const documentReplace = this.Modals.show(DocumentReplaceComponent, {
            initialState: {
                doc: this.doc
            }
        });

        documentReplace.content.onSubmit.subscribe((event: OnSubmitDocumentReplace) => {
            this.Documents.replace(
                event.doc, event.file, event.reason, event.clearExpiration, event.title, event.renameShortcuts
            )
                .subscribe(
                    () => {
                        event.onSuccess();
                    },
                    () => {
                        event.onError();
                    }
                );
        });
    }

    private get hasDocumentSharingPermission(): boolean {
        return !!this.currentTeam?.permissions?.documentSharingView;
    }

    private get hasManageStudiesPermission(): boolean {
        return this.currentTeam?.permissions?.manageTeamStudyProfiles
                || this.currentTeam?.permissions?.viewTeamStudyProfiles;
    }

    private get hasTeamInboxPermission(): boolean {
        return this.currentTeam?.permissions?.viewTeamInbox
            || this.currentTeam?.permissions?.manageTeamInbox
            || this.currentTeam?.permissions?.performActionInTeamInbox;
    }

    private get hasArchivesPermission(): boolean {
        return this.currentTeam?.permissions?.viewArchives && this.currentTeam?.settings?.features?.longTermArchive;
    }

    private getCrumbs(): Crumb[] {
        const {
            teamId, binderId, binderName, folderId, path: docPath, name
        } = this.doc;
        const lineageCrumbs = [];
        const path = docPath as DocumentPathArray;
        if (path) {
            path.forEach((folder) => {
                lineageCrumbs.push({
                    name: folder.name,
                    stateName: 'app.team.folder',
                    stateParams: {
                        teamId,
                        binderId,
                        folderId: folder.id
                    },
                    icon: 'far fa-folder-open'
                });
            });
        }

        return [
            ...(this.hasArchivesPermission ? [{
                name: 'Archives',
                icon: 'fa fa-fw fa fa-history',
                stateName: 'app.team.long-term-archives',
                stateParams: { teamId }
            }] : []
            ),
            {
                name: 'My Downloads',
                icon: 'fa-download',
                stateName: 'app.team.downloads',
                stateParams: { teamId }
            },
            {
                name: 'Dashboard',
                icon: 'fas fa-tachometer-alt',
                stateName: 'app.team.dashboard-timelines',
                stateParams: {
                    teamId,
                    projectId: null
                }
            },
            {
                name: 'Reports',
                icon: 'far fa-newspaper',
                stateName: 'app.team.reports',
                stateParams: { teamId }
            },
            ...(this.hasDocumentSharingPermission ? [{
                name: 'Document Sharing',
                stateName: 'app.team.document-sharing',
                stateParams: {
                    teamId,
                    binderId
                },
                icon: 'fa fa-fw fa-users'
            }] : []
            ),
            ...(this.hasManageStudiesPermission ? [{
                name: 'Studies',
                icon: 'fa fa-fw fa-medkit',
                stateName: 'app.team.manage-studies-by-team',
                stateParams: { teamId }
            }] : []
            ),
            ...(this.hasTeamInboxPermission ? [{
                name: 'Inbox',
                stateName: 'app.team.team-inbox',
                stateParams: {
                    teamId
                },
                icon: 'fa fa-fw fa-envelope'
            }] : []
            ),
            {
                name: 'Binders',
                stateName: 'app.team.binders',
                stateParams: { teamId },
                icon: 'fa-archive'
            },
            {
                name: 'Global View',
                stateName: 'app.team.global-view',
                stateParams: {
                    teamId,
                    objectId: folderId || binderId,
                    objectType: folderId ? 'folder' : 'binder',
                    objectName: folderId ? path[path.length - 1].name : binderName
                },
                icon: 'fa-globe'
            },
            {
                name: 'Announcements',
                stateName: 'app.team.announcements',
                stateParams: { teamId },
                icon: 'fa-bullhorn'
            },
            {
                name: binderName,
                stateName: 'app.team.folder',
                stateParams: {
                    teamId,
                    binderId
                },
                icon: 'fa-book u-font-style-italic'
            },
            ...lineageCrumbs,
            {
                name
            }
        ];
    }

    private constructDocumentFileUrl(): string {
        let documentFileUrl = `${this.AppConfig.config.ebindersApiUrl}/documents/${this.getDocumentId()}/versions/${this.doc.version}/file`;
        if (this.doc.isShortcut) {
            const contentVersion = this.doc.shortcutOf?.lockedToDocumentVersion
                || this.stateParams.contentVersion
                || (typeof this.doc.originalDocument?.id !== 'string' ? this.doc.originalDocument?.id?.version : this.doc.originalDocument?.id);
            documentFileUrl = `${documentFileUrl}?contentVersion=${contentVersion}`;
        }
        return documentFileUrl;
    }

    get isLoadingActionBar(): boolean {
        return this.loadingSignatureQueue || this.loadingTaskQueue || this.loadingTrainingQueue;
    }
}
