import { Injectable, OnDestroy } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {
    interval, merge, of, Subject
} from 'rxjs';
import {
    concatMap, debounceTime, takeUntil, tap, withLatestFrom
} from 'rxjs/operators';
import { CurrentSessionService } from '@app/core/current-session.service';
import { SessionsService } from '../sessions/sessions.service';
import { CookiesService } from '../cookies/cookies.service';
import { ModalRef, ModalsService } from '../modal-helper/modals.service';
import { InactivityWarningComponent } from '../../widgets/inactivity.warning/inactivity.warning.component';
import { Team } from '../models';


@Injectable()
export class SessionIdleService implements OnDestroy {
  private currentTeam: Team;
  private modalWarning: ModalRef<InactivityWarningComponent>;
  private readonly minutesUntilWarning: number;
  private readonly minutesBeingWarned = 2;
  private readonly minutesForHeartbeatInterval = 3;
  private readonly interruptDebounceTime = 1000;
  private readonly cookieCheckIntervalTime = 5000;
  private isServiceInitialized = false;
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private sessions: SessionsService,
    private cookies: CookiesService,
    private modals: ModalsService,
    private CurrentSession: CurrentSessionService
  ) {

      this.currentTeam = this.CurrentSession.getCurrentTeam();
      if (this.currentTeam && this.currentTeam.settings.inactivityTimeout) {
          this.minutesUntilWarning = this.currentTeam.settings.inactivityTimeout - this.minutesBeingWarned;
      }
      else {
          this.minutesUntilWarning = 13;
      }
  }

  public init(): void {
      if (this.isServiceInitialized) {
          return;
      }
      console.log('Starting idle service with idle time of: ', this.minutesUntilWarning);

      this.isServiceInitialized = true;

      this.idle.setIdle(this.minutesUntilWarning * 60);
      this.idle.setTimeout(this.minutesBeingWarned * 60);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.keepalive.interval(this.minutesForHeartbeatInterval * 60);

      this.idle.onIdleEnd.subscribe(() => {
          this.hideWarningModal();
      });

      this.idle.onTimeout.subscribe(() => {
          this.hideWarningModal();
          this.sessions.destroy({ sessionExpired: true });
          this.idle.watch();
      });

      this.idle.onIdleStart.pipe(
          withLatestFrom(this.sessions.isLoggedIn())
      ).subscribe(([, isAuthenticated]) => {
          if (!isAuthenticated) {
              return;
          }
          this.showWarningModal();
      });

      this.keepalive.onPing.pipe(
          withLatestFrom(this.sessions.isLoggedIn()),
          concatMap(([, isAuthenticated]) => {
              if (!isAuthenticated) {
                  return of(null);
              }
              return this.sessions.heartbeat();
          })
      ).subscribe();

      merge(
          this.idle.onInterrupt.pipe(this.applyDebounce(this.interruptDebounceTime)),
          interval(this.cookieCheckIntervalTime)
      )
          .pipe(
              takeUntil(this.unsubscribe$),
              tap(() => {
                  if (!this.cookies.checkIfAuth0CookieExists()) {
                      this.sessions.destroy();
                  }
              })
          )
          .subscribe();

      this.idle.watch();
  }

  applyDebounce<T>(time: number) {
      return debounceTime<T>(time);
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  private hideWarningModal(): void {
      if (this.modalWarning) {
          this.modalWarning.hide();
          this.modalWarning = null;
      }
  }

  private showWarningModal(): void {
      if (this.modalWarning) {
          return;
      }
      this.modalWarning = this.modals.show(InactivityWarningComponent, {
          class: 'modal-md',
          initialState: {}
      });
  }
}
