
import {
    TABLE_CELL_TYPES,
    SIGNING_REASONS,
    DOCUMENT_STATUSES,
    TASK_STATUSES,
    DOCUMENT_TYPES,
    MONITOR_REVIEW_STATUSES
} from '@app/core/constants/';
import { Injectable } from '@angular/core';
import { ColumnConfig, DisplayProperty } from '../../reports-table.service.types';
import { ReportNames } from '../../report-names';

@Injectable()
export class ReportsTableService {

    TABLE_CELL_TYPES = TABLE_CELL_TYPES;

    private readonly approvalsColumns: ColumnConfig[] = [
        {
            renderType: this.TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true,
            sortProperty: 'binderName'
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'updatedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 5
        }
    ];

    private readonly approvalsApprovedColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true,
            sortProperty: 'binderName'

        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Approved By',
            displayProperty: {
                name: 'actorName',
                email: 'actorEmail'
            },
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: false,
            sortProperty: 'actorName'
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Approved At',
            displayProperty: 'actionTimestamp',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 4
        }
    ];

    private readonly approvalsCancelledColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Cancelled By',
            displayProperty: {
                name: 'actorName',
                email: 'actorEmail'
            },
            sortProperty: 'actorName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Cancelled At',
            displayProperty: 'actionTimestamp',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 4
        }
    ];

    private readonly approvalsRejectedColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Rejected By',
            displayProperty: {
                name: 'actorName',
                email: 'actorEmail'
            },
            sortProperty: 'actorName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Rejected At',
            displayProperty: 'actionTimestamp',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 4
        }
    ];

    private readonly signaturesCompleteColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.nameVersion,
            headerName: 'Name',
            displayProperty: {
                name: 'name',
                version: 'version',
                logEntryVersion: 'logEntryVersion',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Signed By',
            displayProperty: {
                name: 'signerName',
                email: 'signerEmail'
            },
            sortProperty: 'signerName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Reason',
            displayProperty: 'reason',
            isSortable: true,
            isSearchable: true,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', ...SIGNING_REASONS.reasons],
            searchValue: '',
            widthValue: 3,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Signed On',
            displayProperty: 'timestamp',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 3
        }
    ];

    private readonly signaturesDeclinedColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.nameVersion,
            headerName: 'Name',
            displayProperty: {
                name: 'name',
                version: 'version',
                logEntryVersion: 'logEntryVersion',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Declined By',
            displayProperty: {
                name: 'signerName',
                email: 'signerEmail'
            },
            sortProperty: 'signerName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Reason',
            displayProperty: 'reason',
            sortProperty: 'reason',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 3,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Declined On',
            displayProperty: 'timestamp',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 3
        }
    ];

    private readonly tagsColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Tag Name',
            displayProperty: { name: 'tagName' },
            sortProperty: 'tagName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Item Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.type,
            headerName: 'Type',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            sortProperty: 'ext',
            displayProperty: null,
            widthValue: 1
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        }
    ];

    private readonly tasksPendingColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.checkbox,
            headerName: null,
            displayProperty: null,
            checkboxCellStateProperty: 'checkboxState',
            widthValue: 1
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'taskName' },
            sortProperty: 'taskName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.multiUsername,
            headerName: 'Assigned To',
            displayProperty: 'assignedTo',
            isSortable: false,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Created By',
            displayProperty: {
                name: 'assignorName',
                email: 'assignorEmail'
            },
            sortProperty: 'assignorName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Status',
            displayProperty: 'taskStatus',
            sortProperty: 'taskStatus',
            isSortable: true,
            isSearchable: true,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', ...TASK_STATUSES.statuses],
            searchValue: '',
            widthValue: 4,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'modifiedAt',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 5
        }
    ];

    private readonly tasksMyQueueColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'taskName' },
            sortProperty: 'taskName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 6,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 7,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Created By',
            displayProperty: {
                name: 'assignorName',
                email: 'assignorEmail'
            },
            sortProperty: 'assignorName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Status',
            displayProperty: 'taskStatus',
            sortProperty: 'taskStatus',
            isSortable: true,
            isSearchable: false,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', ...TASK_STATUSES.statuses],
            searchValue: '',
            widthValue: 3,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'modifiedAt',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 4
        }
    ];

    private readonly tasksClosedColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'taskName' },
            sortProperty: 'taskName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Closed By',
            displayProperty: {
                name: 'closedByUserName',
                email: 'closedByUserEmail'
            },
            sortProperty: 'closedByUserName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Closed On',
            displayProperty: 'modifiedAt',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 3
        }
    ];

    private readonly dueDatesColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 6,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 6,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Due Date',
            displayProperty: 'dueDate',
            isSortable: true,
            isSearchable: true,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', 'With Due Date', 'Without Due Date'],
            searchValue: '',
            widthValue: 4,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'updatedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 4
        }
    ];

    private readonly dueDatesColumnsWithoutAllPlaceholders: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 6,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 6,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Due Date',
            displayProperty: 'dueDate',
            isSortable: true,
            isSearchable: false,
            widthValue: 4,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'updatedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 4
        }
    ];

    private readonly expirationDatesColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.type,
            headerName: 'Type',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            sortProperty: 'ext',
            displayProperty: null,
            widthValue: 2
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 6,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 6,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Expiration Date',
            displayProperty: 'expirationDate',
            dateOnly: true,
            isSortable: true,
            isSearchable: false,
            widthValue: 4,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'updatedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 4
        }
    ];

    private readonly monitorReviewsApprovedColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Unique Protocol ID',
            displayProperty: 'monitorReviewName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Version',
            displayProperty: 'version',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 2,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.status,
            headerName: 'Status',
            displayProperty: 'status',
            isSortable: false,
            isSearchable: false,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', ...DOCUMENT_STATUSES.statuses],
            searchValue: '',
            widthValue: 2,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Performed By',
            displayProperty: {
                name: 'approvedByUserName',
                email: 'approvedByUserEmail'
            },
            sortProperty: 'approvedByUserName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Date',
            displayProperty: 'approvedAt',
            sortProperty: 'approvedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 3
        }
    ];

    private readonly signaturesPendingColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.checkbox,
            headerName: null,
            displayProperty: null,
            checkboxCellStateProperty: 'checkboxState',
            widthValue: 1
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: {
                name: 'name',
                logEntryRow: 'logEntryRow'
            },
            latestVersion: true,
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Pending Signer',
            displayProperty: {
                name: 'requesteeName',
                email: 'requesteeEmail'
            },
            sortProperty: 'requesteeName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Requested By',
            displayProperty: {
                name: 'requesterName',
                email: 'requesterEmail'
            },
            sortProperty: 'requesterName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Requested On',
            displayProperty: 'requestedOn',
            isSortable: true,
            isSearchable: false,
            widthValue: 4
        }
    ];

    private readonly approvalsPendingSignaturesColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Pending Signer',
            displayProperty: {
                name: 'requesteeName',
                email: 'requesteeEmail'
            },
            sortProperty: 'requesteeName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Requested By',
            displayProperty: {
                name: 'requesterName',
                email: 'requesterEmail'
            },
            sortProperty: 'requesterName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Requested At',
            displayProperty: 'requestedOn',
            isSortable: false,
            isSearchable: false,
            widthValue: 4
        }
    ];

    private readonly signaturesMyQueueColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: {
                name: 'name',
                logEntryRow: 'logEntryRow'
            },
            latestVersion: true,
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Requested By',
            displayProperty: {
                name: 'requesterName',
                email: 'requesterEmail'
            },
            sortProperty: 'requesterName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Reason',
            displayProperty: 'reason',
            isSortable: true,
            isSearchable: true,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', ...SIGNING_REASONS.reasons],
            searchValue: '',
            widthValue: 3,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Sign By Date',
            displayProperty: 'signByDate',
            isSortable: true,
            isSearchable: false,
            default: 'None',
            widthValue: 3
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Requested On',
            displayProperty: 'requestedOn',
            isSortable: true,
            isSearchable: false,
            widthValue: 4
        }
    ];

    private readonly signaturesSignByDateColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.checkbox,
            headerName: null,
            displayProperty: null,
            checkboxCellStateProperty: 'checkboxState',
            widthValue: 1
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            latestVersion: true,
            displayProperty: {
                name: 'name',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name',
                logEntryRow: 'logEntryRow'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Pending Signer',
            displayProperty: {
                name: 'requesteeName',
                email: 'requesteeEmail'
            },
            sortProperty: 'requesteeName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Sign By Date',
            displayProperty: 'signByDate',
            isSortable: true,
            isSearchable: false,
            widthValue: 4
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Requested By',
            displayProperty: {
                name: 'requesterName',
                email: 'requesterEmail'
            },
            sortProperty: 'requesterName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Requested On',
            displayProperty: 'requestedOn',
            isSortable: true,
            isSearchable: false,
            widthValue: 4
        }
    ];

    private readonly monitorReviewsOpenColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Unique Protocol ID',
            displayProperty: 'monitorReviewName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Item Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: false,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Last Modified By',
            displayProperty: {
                name: 'openedByUserName',
                email: 'openedByUserEmail'
            },
            sortProperty: 'openedByUserName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified Date',
            displayProperty: 'modifiedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 3
        }
    ];

    private readonly labelsColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Label',
            displayProperty: { name: 'labelName' },
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false,
            sortProperty: 'labelName'
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Label Value',
            displayProperty: { name: 'value' },
            sortProperty: 'value',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Label Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'location'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Date Applied',
            displayProperty: 'assignedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 5,
            truncate: false
        }
    ];

    private readonly sipDocumentStatusesColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.nameVersion,
            headerName: 'Name',
            displayProperty: {
                name: 'name',
                version: 'version'
            },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'SIP Status',
            displayProperty: 'status',
            isSortable: false,
            isSearchable: false,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', 'Approved', 'Rejected', 'Request for Revision'],
            searchValue: '',
            widthValue: 3,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'SIP Version',
            displayProperty: 'sipVersion',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 3,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Date',
            displayProperty: 'timestamp',
            isSortable: true,
            isSearchable: false,
            widthValue: 5,
            truncate: false
        }
    ];

    private readonly sipDocumentSentColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Version',
            displayProperty: 'version',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 3,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Date Sent',
            displayProperty: 'timestamp',
            isSortable: true,
            isSearchable: false,
            widthValue: 5,
            truncate: false
        }
    ];

    private readonly documentsRecentlyUploadedColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.type,
            headerName: 'Type',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            sortProperty: 'ext',
            displayProperty: null,
            widthValue: 2
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Version',
            displayProperty: 'version',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 2,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Who',
            displayProperty: {
                name: 'uploaderName',
                email: 'uploaderEmail'
            },
            sortProperty: 'uploaderName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Uploaded Date',
            displayProperty: 'uploadedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 5,
            truncate: false
        }
    ];

    private readonly documentsOverviewColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Type',
            isSortable: false,
            isSearchable: true,
            searchFieldType: 'multiSelect',
            searchFieldOptions: [...DOCUMENT_TYPES.types],
            searchFieldPreselected: [...DOCUMENT_TYPES.types],
            searchValue: '',
            sortProperty: 'documentType',
            displayProperty: 'documentType',
            widthValue: 3
        },
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'name' },
            sortProperty: 'name',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Version',
            displayProperty: 'documentVersion',
            isSortable: false,
            isSearchable: true,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', 'Latest'],
            searchValue: '',
            widthValue: 2,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        },
        {
            renderType: TABLE_CELL_TYPES.location,
            headerName: 'Location',
            displayProperty: {
                binderName: 'binderName',
                foldersPath: 'path',
                documentName: 'name'
            },
            sortProperty: 'binderName',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: true
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Uploaded by',
            displayProperty: {
                name: 'uploaderName',
                email: 'uploaderEmail'
            },
            sortProperty: 'uploaderName',
            isSortable: false,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Uploaded Date',
            displayProperty: 'uploadedAt',
            isSortable: true,
            isSearchable: false,
            widthValue: 4,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Last MRM Status',
            isSortable: false,
            isSearchable: true,
            searchFieldType: 'multiSelect',
            searchFieldOptions: [...MONITOR_REVIEW_STATUSES.statuses],
            searchFieldPreselected: [...MONITOR_REVIEW_STATUSES.statuses],
            searchValue: '',
            displayProperty: 'monitorReviewStatus',
            widthValue: 3,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Expiration Date',
            displayProperty: 'expirationDate',
            isSortable: true,
            isSearchable: false,
            widthValue: 4,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Last Modified',
            displayProperty: 'lastModifiedDate',
            isSortable: true,
            isSearchable: false,
            widthValue: 4,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        }
    ];

    private readonly qcReportColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'documentName' },
            sortProperty: 'documentName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Version',
            displayProperty: 'documentVersion',
            isSortable: false,
            isSearchable: true,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', 'Latest'],
            searchValue: '',
            widthValue: 2,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        },
        {
            renderType: TABLE_CELL_TYPES.qcStatus,
            headerName: 'Status',
            displayProperty: 'status',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Review Cycle',
            displayProperty: 'cycle',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 3,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Cycle Started',
            displayProperty: 'activeSince',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 4,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Requestor',
            displayProperty: {
                name: 'requestorName',
                email: 'requestorEmail'
            },
            sortProperty: 'requestorName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Reviewer',
            displayProperty: {
                name: 'reviewerName',
                email: 'reviewerEmail'
            },
            sortProperty: 'reviewerName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Request Created',
            displayProperty: 'createdAt',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 4,
            truncate: false
        }
    ];

    private readonly qcReviewColumns: ColumnConfig[] = [
        {
            renderType: TABLE_CELL_TYPES.name,
            headerName: 'Name',
            displayProperty: { name: 'documentName' },
            sortProperty: 'documentName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Version',
            displayProperty: 'documentVersion',
            isSortable: false,
            isSearchable: true,
            searchFieldType: 'singleSelect',
            searchFieldOptions: ['All', 'Latest'],
            searchValue: '',
            widthValue: 2,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        },
        {
            renderType: TABLE_CELL_TYPES.qcStatus,
            headerName: 'Status',
            displayProperty: 'status',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.text,
            headerName: 'Review Cycle',
            displayProperty: 'cycle',
            isSortable: false,
            isSearchable: false,
            searchValue: '',
            widthValue: 3,
            truncate: false,
            cellContainerClasses: 'u-justify-content-center',
            headerClasses: 'u-text-align-center'
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Cycle Started',
            displayProperty: 'activeSince',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 4,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Requestor',
            displayProperty: {
                name: 'requestorName',
                email: 'requestorEmail'
            },
            sortProperty: 'requestorName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.username,
            headerName: 'Reviewer',
            displayProperty: {
                name: 'reviewerName',
                email: 'reviewerEmail'
            },
            sortProperty: 'reviewerName',
            isSortable: true,
            isSearchable: true,
            searchValue: '',
            widthValue: 5,
            truncate: false
        },
        {
            renderType: TABLE_CELL_TYPES.date,
            headerName: 'Request Created',
            displayProperty: 'createdAt',
            isSortable: true,
            isSearchable: false,
            searchValue: '',
            widthValue: 4,
            truncate: false
        }
    ];

    public reportToColumnHash: Record<string, ColumnConfig[]> | Record<string, never>;

    public getFilterFieldFromColumnConfig(column: ColumnConfig) {
        switch (column.renderType) {
            case TABLE_CELL_TYPES.multiUsername:
                return `${column.displayProperty}.name`;
            case TABLE_CELL_TYPES.name:
                return (column.displayProperty as DisplayProperty).name;
                // return column.displayProperty;
            case TABLE_CELL_TYPES.nameVersion:
                return (column.displayProperty as DisplayProperty).name;
            case TABLE_CELL_TYPES.status:
                return column.displayProperty;
            case TABLE_CELL_TYPES.text:
                return column.displayProperty;
            case TABLE_CELL_TYPES.username:
                return (column.displayProperty as DisplayProperty).name;
            case TABLE_CELL_TYPES.date:
                return column.displayProperty;
            default:
                return '';
        }
    }

    public getFilterTypeFromColumnConfig(column: ColumnConfig) {
        if (column.searchFieldType && (column.searchFieldType === 'singleSelect' || column.searchFieldType === 'multiSelect')) {
            return 'select';
        }

        return 'text';
    }

    constructor() {
        this.reportToColumnHash = {};
        this.reportToColumnHash[ReportNames.approvalsApproved] = this.approvalsApprovedColumns;
        this.reportToColumnHash[ReportNames.approvalsCancelled] = this.approvalsCancelledColumns;
        this.reportToColumnHash[ReportNames.approvalsPendingForm] = this.approvalsColumns;
        this.reportToColumnHash[ReportNames.approvalsReadyForApproval] = this.approvalsColumns;
        this.reportToColumnHash[ReportNames.approvalsRejected] = this.approvalsRejectedColumns;
        this.reportToColumnHash[ReportNames.tags] = this.tagsColumns;
        this.reportToColumnHash[ReportNames.tasksPending] = this.tasksPendingColumns;
        this.reportToColumnHash[ReportNames.tasksMyQueue] = this.tasksMyQueueColumns;
        this.reportToColumnHash[ReportNames.tasksClosed] = this.tasksClosedColumns;
        this.reportToColumnHash[ReportNames.pastDueDates] = this.dueDatesColumns;
        this.reportToColumnHash[ReportNames.dueDates] = this.dueDatesColumns;
        this.reportToColumnHash[ReportNames.pastExpirationDates] = this.expirationDatesColumns;
        this.reportToColumnHash[ReportNames.expirationDates] = this.expirationDatesColumns;
        this.reportToColumnHash[ReportNames.monitorReviewsApproved] = this.monitorReviewsApprovedColumns;
        this.reportToColumnHash[ReportNames.approvalsPendingSignatures] = this.approvalsPendingSignaturesColumns;
        this.reportToColumnHash[ReportNames.signaturesMyQueue] = this.signaturesMyQueueColumns;
        this.reportToColumnHash[ReportNames.signaturesSignByDate] = this.signaturesSignByDateColumns;
        this.reportToColumnHash[ReportNames.signaturesPending] = this.signaturesPendingColumns;
        this.reportToColumnHash[ReportNames.signaturesDeclined] = this.signaturesDeclinedColumns;
        this.reportToColumnHash[ReportNames.signaturesCompleted] = this.signaturesCompleteColumns;
        this.reportToColumnHash[ReportNames.monitorReviewsOpen] = this.monitorReviewsOpenColumns;
        this.reportToColumnHash[ReportNames.labels] = this.labelsColumns;
        this.reportToColumnHash[ReportNames.sipDocumentStatuses] = this.sipDocumentStatusesColumns;
        this.reportToColumnHash[ReportNames.sipDocumentsSent] = this.sipDocumentSentColumns;
        this.reportToColumnHash[ReportNames.qcReport] = this.qcReportColumns;
        this.reportToColumnHash[ReportNames.qcReviewMyQueue] = this.qcReviewColumns;

        this.reportToColumnHash[ReportNames.documentsRecentlyUploaded] = this.documentsRecentlyUploadedColumns;
        this.reportToColumnHash[ReportNames.documentsOverview] = this.documentsOverviewColumns;
    }

    getColumns(reportName: string, isAllPlaceholdersReportEnabled: boolean): ColumnConfig[] {
        if (isAllPlaceholdersReportEnabled !== undefined && !isAllPlaceholdersReportEnabled
            && reportName === ReportNames.dueDates) {
            this.reportToColumnHash[ReportNames.dueDates] = this.dueDatesColumnsWithoutAllPlaceholders;
            return this.reportToColumnHash[ReportNames.dueDates] || [];
        }
        return this.reportToColumnHash[reportName] || [];
    }

    updateReportColumn(reportName: string, column: ColumnConfig) {
        const foundIndex = this.reportToColumnHash[reportName]
            .findIndex((foundColumn) => foundColumn.headerName === column.headerName);

        if (foundIndex >= 0) {
            this.reportToColumnHash[reportName][foundIndex] = column;
        }
    }

    clearAllColumnFilters() {
        Object.keys(this.reportToColumnHash).forEach((reportName) => {
            this.reportToColumnHash[reportName].forEach((column) => {
                if (reportName === ReportNames.documentsOverview && column.headerName === 'Type') {
                    column.searchFieldPreselected = [...DOCUMENT_TYPES.types];
                }
                if (column.searchValue) {
                    column.searchValue = '';
                }
            });
        });
    }

    getDefaultSort(reportName: string): string {

        switch (reportName) {
            case ReportNames.pastDueDates:
            case ReportNames.dueDates:
                return 'dueDate';

            case ReportNames.pastExpirationDates:
            case ReportNames.expirationDates:
                return 'expirationDate';

            case ReportNames.signaturesCompleted:
                return 'timestamp';

            case ReportNames.signaturesPending:
                return 'requestedOn';

            case ReportNames.documentsRecentlyUploaded:
            case ReportNames.documentsOverview:
                return 'uploadedAt';

            case ReportNames.signaturesMyQueue:
            case ReportNames.signaturesSignByDate:
                return 'signByDate';

            case ReportNames.tasksClosed:
            case ReportNames.tasksMyQueue:
            case ReportNames.tasksPending:
            case ReportNames.monitorReviewsOpen:
                return 'modifiedAt';

            case ReportNames.labels:
                return 'labelName';

            case ReportNames.monitorReviewsApproved:
                return 'approvedAt';

            case ReportNames.qcReport:
            case ReportNames.qcReviewMyQueue:
                return 'createdAt';

            default:
                return 'name';
        }
    }

    getDefaultSortDirection(reportName: string): string {
        switch (reportName) {
            case ReportNames.pastDueDates:
            case ReportNames.dueDates:
            case ReportNames.pastExpirationDates:
            case ReportNames.expirationDates:
            case ReportNames.signaturesCompleted:
            case ReportNames.signaturesPending:
            case ReportNames.signaturesMyQueue:
            case ReportNames.signaturesSignByDate:
            case ReportNames.tasksClosed:
            case ReportNames.tasksMyQueue:
            case ReportNames.tasksPending:
            case ReportNames.monitorReviewsOpen:
            case ReportNames.labels:
            case ReportNames.tags:
            case ReportNames.qcReport:
            case ReportNames.qcReviewMyQueue:
                return 'ASC';
            case ReportNames.monitorReviewsApproved:
            case ReportNames.documentsRecentlyUploaded:
            case ReportNames.documentsOverview:
                return 'DESC';
            default:
                return 'ASC';
        }
    }
}
