import {
    Component, Input, OnChanges, SimpleChanges
} from '@angular/core';
import { TRAINING_STATUSES } from '@florencehealthcare/florence-constants/lib/user-roles';
import {
    BehaviorSubject, combineLatest,
    Observable
} from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamUserWithRoles } from '../../containers/manage-team-members/manage-team-members.component.types';
import template from './manage-team-members-roles-cell.component.html';
import styles from './manage-team-members-roles-cell.component.scss';
import { RoleChipDetails } from './utils/role-chip-details/role-chip-details';
import { TeamOwnerRoleChipDetails } from './utils/team-owner-role-chip-details/team-owner-role-chip-details';
import { IRoleChipDetails } from './utils/types/i-role-chip-details';

@Component({
    selector: 'manage-team-members-roles-cell',
    template,
    styles: [String(styles)]
})
export class ManageTeamMembersRoleCellComponent implements OnChanges {
    @Input() availableWidthInPx;

    @Input() user: TeamUserWithRoles = {} as TeamUserWithRoles;

    private maxChipWidthInPxThatMatchesCSS = 175;

    private plusXMoreTextWidthAllowanceInPx = 75;

    private roleChips = new BehaviorSubject<IRoleChipDetails[]>([]);

    totalRoleChipsCount$ = this.initializeTotalRoleChipsCount()

    visibleRoleChipsCount$ = this.initializeVisibleRoleChipsCount()

    visibleRoleChips$ = this.initializeVisibleRoleChips()

    notVisibleRoleChipsCount$ = this.initializeNotVisibleRoleChipsCount()

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.availableWidthInPx || changes.user) {
            this.setRoleChips();
        }
    }

    isActive({ isActive, trainingStatus, start }: RoleChipDetails): boolean {
        const currentDate = new Date();
        const startDate = new Date(start || null);
        return isActive
                && trainingStatus !== TRAINING_STATUSES.PENDING
                && (startDate === new Date(null) || startDate.getTime() <= currentDate.getTime());
    }

    isInactive({ isActive, trainingStatus }: RoleChipDetails): boolean {
        return !isActive && trainingStatus !== TRAINING_STATUSES.PENDING;
    }

    isTrainingRequired({ trainingStatus }: RoleChipDetails): boolean {
        return trainingStatus === TRAINING_STATUSES.PENDING;
    }

    isScheduled({ isActive, trainingStatus, start }: RoleChipDetails): boolean {
        const currentDate = new Date();
        const startDate = new Date(start);
        return isActive
               && trainingStatus !== TRAINING_STATUSES.PENDING
               && startDate
               && startDate.getTime() > currentDate.getTime();
    }

    private setRoleChips(): void {
        const roleChips = [];

        if (this.user.isTeamAdmin) {
            roleChips.push(new TeamOwnerRoleChipDetails());
        }

        const roleChipDetails = this.user.roles.map(
            (role) => new RoleChipDetails(
                role.name,
                role.isActive,
                role.trainingStatus,
                role.start
            )
        );

        roleChips.push(...roleChipDetails);

        this.roleChips.next(roleChips);
    }

    private initializeTotalRoleChipsCount(): Observable<number> {
        return this.roleChips.pipe(
            map((roleChips) => {
                return roleChips.length;
            })
        );
    }

    private initializeVisibleRoleChipsCount(): Observable<number> {
        return this.roleChips.pipe(
            map((roleChips) => {
                const minimumChipsToShow = 1;

                const maxCountOfChipsThatWillFitWidth = Math.floor(
                    (this.availableWidthInPx - this.plusXMoreTextWidthAllowanceInPx) / (this.maxChipWidthInPxThatMatchesCSS)
                );


                if (roleChips.length === 0) {
                    return 0;
                }
                if (roleChips.length < minimumChipsToShow) {
                    return minimumChipsToShow;
                }
                if (minimumChipsToShow > maxCountOfChipsThatWillFitWidth) {
                    return minimumChipsToShow;
                }
                if (roleChips.length > maxCountOfChipsThatWillFitWidth) {
                    return maxCountOfChipsThatWillFitWidth;
                }
                return roleChips.length;
            })
        );
    }

    private initializeVisibleRoleChips(): Observable<RoleChipDetails[]> {
        return combineLatest([
            this.roleChips.asObservable(),
            this.visibleRoleChipsCount$
        ]).pipe(
            map(([roleChips, visibleRoleChipsCount]) => {
                return roleChips.slice(0, visibleRoleChipsCount);
            })
        );
    }

    private initializeNotVisibleRoleChipsCount(): Observable<number> {
        return combineLatest([
            this.roleChips.asObservable(),
            this.visibleRoleChipsCount$
        ]).pipe(
            map(([roleChips, visibleRoleChipsCount]) => {
                return roleChips.length - visibleRoleChipsCount;
            })
        );
    }
}
