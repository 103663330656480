import { Component, Input } from '@angular/core';
import template from './role-chip-scheduled.component.html';
import { IChipIcon } from '../../../../widgets/chip/types/i-chip-icon';

@Component({
    selector: 'role-chip-scheduled',
    template
})
export class RoleChipScheduledComponent {
    @Input() roleName: string;

    icon: IChipIcon = {
        name: 'fa-history',
        color: '#728497'
    }
}
