import {
    ChangeDetectionStrategy,
    Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren
} from '@angular/core';
import {
    debounceTime,
    filter,
    map, startWith, takeUntil, tap
} from 'rxjs/operators';
import {
    BehaviorSubject, Observable, Subject, merge
} from 'rxjs';
import {
    AbstractControl,
    ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import * as _ from 'lodash';
import { MESSAGES, REGEX } from '@app/core/constants';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { FilteredSelectComponent, FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import {
    ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT,
    AddRoleResponsibilityButtonPlacement,
    DOA_LOG_TEMPLATE_FORM_CONTROLS,
    DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES,
    DOA_LOG_TEMPLATE_STEP_NAMES
} from '../doa-log-template.types';
import template from './doa-log-template-study-roles-step.component.html';
import { AbstractWizardStep } from '../../../../../widgets/wizard/utils/abstract-wizard-step/abstract-wizard-step.component';
import { notBlank } from '../../../../../core/form-validators';
import stepStyles from '../doa-log-template-steps.style.scss';
import componentStyles from './doa-log-template-study-roles-step.component.scss';
import { StudyResponsibilities, StudyResponsibility, StudyRole } from '../../../../../shared/models';
import { StudyRoleIdName, StudyRolesMap } from '../../../../../shared/study-roles/study-roles.types';
import { FormUtil } from '../../../../../shared/forms/utils/form.util';
import { LogTemplateUtil } from '../../../utils/log-template.util';

@Component({
    selector: 'doa-log-template-study-roles-step',
    template,
    styles: [String(stepStyles), String(componentStyles)],
    // eslint-disable-next-line no-use-before-define
    providers: [{ provide: AbstractWizardStep, useExisting: DoaLogTemplateStudyRolesStepComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoaLogTemplateStudyRolesStepComponent extends AbstractWizardStep implements OnInit, OnChanges, OnDestroy {
    constructor(
        @Inject(ControlContainer) controlContainer: ControlContainer,
        private formBuilder: FormBuilder,
        private FeatureFlags: FeatureFlagService
    ) {
        super(controlContainer);
    }

    readonly buttonPlacementTopRight = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.TOP_RIGHT;
    readonly buttonPlacementTopLeft = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.TOP_LEFT;
    readonly buttonPlacementBelow = ADD_ROLE_RESPONSIBILITY_BUTTON_PLACEMENT.BELOW;

    readonly validationErrBlankMessage = MESSAGES.invalidBlankMessage;
    readonly validationErrMediumMessage = MESSAGES.validMediumNameMessage;

    @Input() studyRolesWithResponsibilityIds?: StudyRole[];
    @Input() studyRolesList: StudyRoleIdName[];
    @Input() studyRolesMap: StudyRolesMap;
    @Input() studyResponsibilities: StudyResponsibilities;
    @Input() addStudyRoleButtonPlacement: AddRoleResponsibilityButtonPlacement = this.buttonPlacementBelow;
    @Input() isLogDocument = false;

    @ViewChildren('studyRoleItemsRef') studyRoleItemsRef: QueryList<ElementRef>;
    @ViewChildren('studyRolesDropdown') studyRolesDropdown: QueryList<ElementRef>;
    @ViewChildren('studyResponsibilititesDropdown') studyResponsibilititesDropdown: QueryList<ElementRef>;
    @ViewChildren('studyRoleSearch') studyRoleSearchElements: QueryList<FilteredSelectComponent>;

    readonly stepFriendlyName = DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_ROLES;
    readonly stepFormControlName = DOA_LOG_TEMPLATE_STEP_FORM_CONTROL_NAMES.STUDY_ROLES;
    readonly studyRolesStepName = DOA_LOG_TEMPLATE_STEP_NAMES.STUDY_ROLES;
    readonly rolesFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.ROLES;
    readonly reasonFormControlName = DOA_LOG_TEMPLATE_FORM_CONTROLS.REASON;

    studyRolesFormArray: FormArray;
    reasonFormControl?: FormControl

    private filteredStudyRolesList: BehaviorSubject<StudyRoleIdName[]> = new BehaviorSubject([]);
    filteredStudyRolesList$ = this.filteredStudyRolesList.asObservable();

    formUtil = new FormUtil();

    private studyRolesListChanged = new Subject<void>();
    studyRolesListChanged$ = this.studyRolesListChanged.asObservable();

    private originalStudyRoles = new Map();

    private readonly destroy$ = new Subject<void>();
    filterControls = new Map<number, FormControl>();
    private filteredStudyRolesLists = new Map<number, BehaviorSubject<StudyRoleIdName[]>>();
    searchEnabled = true;

    rolesAndResponsibilitiesOptions:{
        [key: number]: StudyRoleIdName[];
    } = {};

    filterValues:{
        [key: number]: string;
    } = {};

    ngOnInit(): void {
        this.FeatureFlags.getFlag(FEATURE_FLAGS.EBINDERS_SEARCH_STUDY_ROLES, true).pipe(
            filter((flag) => flag !== undefined),
            debounceTime(300),
            tap((isFlagEnabled) => {
                this.searchEnabled = isFlagEnabled;
            })
        ).subscribe();

        this.initFormControls();

        this.setFilteredStudyRolesListSubscription();
        this.studyRolesListChanged.next();

        this.setOriginalStudyRoles();

        this.syncStepValidityWithFormGroupStatusChanges$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();

        this.stepFormGroup.updateValueAndValidity();

        this.formUtil.scrollElementIntoView$().pipe(
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.studyRolesList?.currentValue.length > 0) {
            this.studyRolesListChanged.next();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.filterControls.clear();
        this.filteredStudyRolesLists.clear();
    }

    onDrop(event: CdkDragDrop<string[]>) {
        FormUtil.reorderFormArrayFromDragDropEvent(event, this.studyRolesFormArray);

        this.stepFormGroup.markAsDirty();
        this.studyRolesFormArray.updateValueAndValidity();
    }

    onMultichoiceItemSelect(
        event: PointerEvent,
        logTemplateStudyRoleIndex: number,
        selectedStudyResponsibilityId: StudyResponsibility['_id']
    ): void {
        event.stopPropagation();
        const inputElement = <HTMLInputElement>event.target;
        const { checked } = inputElement;

        if (checked) {
            this.addStudyResponsibilityToRole(logTemplateStudyRoleIndex, selectedStudyResponsibilityId);
        }
        else {
            this.removeStudyResponsibilityFromRole(logTemplateStudyRoleIndex, selectedStudyResponsibilityId);
        }
    }

    onSelectStudyRole(
        logTemplateStudyRoleIndex: number,
        selectedStudyRoleId: StudyRoleIdName['_id']
    ) {
        const logTemplateStudyRoleFormGroup = <FormGroup> this.studyRolesFormArray.at(logTemplateStudyRoleIndex);

        logTemplateStudyRoleFormGroup.patchValue({
            studyRole: selectedStudyRoleId
        });
    }

    onAddStudyRole(): void {
        this.addEmptyStudyRole();

        this.scrollToViewport();
    }

    focusStudyResponsibilitiesDropdown(index: number): void {
        const dropdownElement = this.studyResponsibilititesDropdown.toArray()[index]?.nativeElement;
        if (!dropdownElement) {
            return;
        }
        dropdownElement.scrollIntoView({ behavior: 'smooth' });
    }

    focusStudyRolesDropdown(): void {
        this.formUtil.triggerScrollToElement(this.studyRolesDropdown);
    }

    getSelectedResponsibilityObjectsForStudyRoleSorted$(logTemplateStudyRoleIndex: number): Observable<StudyResponsibility[]> {
        return this.getSelectedResponsibilityObjectsForStudyRole$(logTemplateStudyRoleIndex).pipe(
            map((selectedResponsibilitiesObjects) => {
                const selectedResponsibilitiesObjectsSorted = selectedResponsibilitiesObjects
                    .sort(this.sortStudyResponsibilities.bind(this));

                return selectedResponsibilitiesObjectsSorted;
            })
        );
    }

    getSelectedStudyRoleName(logTemplateStudyRoleIndex: number): string {
        const studyRoleFormGroup = this.getStudyRoleFormGroup(logTemplateStudyRoleIndex);

        const selectedStudyRoleName = this.studyRolesMap.get(studyRoleFormGroup.value.studyRole);

        return selectedStudyRoleName;
    }

    isResponsibilityChecked$(
        logTemplateStudyRoleIndex: number,
        responsibilityId: StudyResponsibility['_id']
    ): Observable<boolean> {
        return this.getSelectedResponsibilityIdsForStudyRole$(logTemplateStudyRoleIndex).pipe(
            map((selectedResponsibilityIds) => {
                return selectedResponsibilityIds
                    .some((selectedResponsibilityId) => selectedResponsibilityId === responsibilityId);
            })
        );
    }

    addStudyResponsibilityToRole(
        logTemplateStudyRoleIndex: number,
        responsibilityIdToAdd: StudyResponsibility['_id']
    ): void {
        const studyRoleFormGroup = this.getStudyRoleFormGroup(logTemplateStudyRoleIndex);

        const responsibilityIdsFormArray = studyRoleFormGroup.controls.responsibilityIds as FormArray;

        responsibilityIdsFormArray.push(new FormControl(responsibilityIdToAdd));

        this.setIsStudyRoleChanged(studyRoleFormGroup, responsibilityIdsFormArray);
    }

    removeStudyResponsibilityFromRole(
        logTemplateStudyRoleIndex: number,
        responsibilityIdToRemove: StudyResponsibility['_id']
    ): void {
        const studyRoleFormGroup = this.getStudyRoleFormGroup(logTemplateStudyRoleIndex);

        const responsibilityIdsFormArray = studyRoleFormGroup.controls.responsibilityIds as FormArray;

        const indexToRemove = responsibilityIdsFormArray.controls
            .findIndex((control) => control.value === responsibilityIdToRemove);
        responsibilityIdsFormArray.removeAt(indexToRemove);

        this.setIsStudyRoleChanged(studyRoleFormGroup, responsibilityIdsFormArray);
    }

    addEmptyStudyRole(): void {
        const emptyStudyRole = this.generateStudyRoleFormGroup();

        this.studyRolesFormArray.push(emptyStudyRole);
    }

    removeFormArrayItem(index: number): void {
        this.studyRolesFormArray.removeAt(index);
        const control = this.filterControls.get(index);
        if (control) {
            control.setValue(null);
        }
        this.filterControls.delete(index);
        this.filteredStudyRolesLists.delete(index);
        this.stepFormGroup.markAsDirty();
    }

    formatResponsibilityNameWithIdenfitifer(studyResponsibility: StudyResponsibility): string {
        const studyResponsibilityIndex = this.getStudyResponsibilityIndex(studyResponsibility._id);
        const identifier = LogTemplateUtil.getNumberOrLetterIdentifier(
            studyResponsibilityIndex,
            this.studyResponsibilities.isNumberIdentifier
        );

        return `${identifier}. ${studyResponsibility.name}`;
    }

    getFormControlErrorMessage(
        formControl: AbstractControl
    ): { message: string } {
        let message = '';

        switch (true) {
            case formControl.errors?.required:
            case formControl.errors?.blank:
                message = this.validationErrBlankMessage;
                break;
            case !!formControl.errors?.pattern:
            case formControl.errors?.maxlength:
                message = this.validationErrMediumMessage;
                break;
            default:
                break;
        }

        return { message };
    }

    private setFilteredStudyRolesListSubscription(): void {
        merge(
            this.studyRolesFormArray.valueChanges,
            this.studyRolesListChanged$
        ).pipe(
            takeUntil(this.destroy$),
            tap(() => {
                const selectedStudyRoleIds = new Set(this.studyRolesFormArray.value.map(({ studyRole }) => studyRole));
                const filteredStudyRoles = this.studyRolesList.filter(({ _id }) => !selectedStudyRoleIds.has(_id));
                this.filteredStudyRolesList.next(filteredStudyRoles);
            })
        ).subscribe();
    }

    private getStudyResponsibilityIndex(responsibilityId: string) {
        return this.studyResponsibilities.values.findIndex((r) => r._id === responsibilityId);
    }

    private initFormControls(): void {
        const studyRoles = this.getInitialStudyRolesFormArrayValue();

        this.studyRolesFormArray = this.formBuilder.array(
            studyRoles,
            [Validators.required]
        );

        this.getFilteredList();

        if (this.isLogDocument) {
            this.reasonFormControl = this.formBuilder.control(
                '',
                [
                    Validators.required,
                    notBlank,
                    Validators.pattern(REGEX.names),
                    Validators.maxLength(1000)
                ]
            );
        }

        this.parentForm.addControl(
            this.stepFormControlName,
            this.formBuilder.group({
                [this.rolesFormControlName]: this.studyRolesFormArray,
                ...(this.isLogDocument && {
                    [this.reasonFormControlName]: this.reasonFormControl
                })
            })
        );
    }

    private getInitialStudyRolesFormArrayValue(): FormGroup[] {
        let initialStudyRolesFormArrayValue: FormGroup[];

        if (this.studyRolesWithResponsibilityIds?.length) {
            initialStudyRolesFormArrayValue = this.studyRolesWithResponsibilityIds.map(
                (studyRoleWithResponsibilityIds) => this.generateStudyRoleFormGroup(studyRoleWithResponsibilityIds)
            );
        }
        else {
            initialStudyRolesFormArrayValue = [this.generateStudyRoleFormGroup()];
        }

        return initialStudyRolesFormArrayValue;
    }

    private getSelectedResponsibilityObjectsForStudyRole$(logTemplateStudyRoleIndex: number): Observable<StudyResponsibility[]> {
        return this.getSelectedResponsibilityIdsForStudyRole$(logTemplateStudyRoleIndex).pipe(
            map((responsibilityIds) => {
                const selectedResponsibilitiesWithNames = responsibilityIds.map((responsibilityId) => {
                    const studyResponsibilityWithName = this.studyResponsibilities.values
                        .find((responsibility) => responsibility._id === responsibilityId);

                    return studyResponsibilityWithName;
                });

                return selectedResponsibilitiesWithNames;
            })
        );
    }

    private getSelectedResponsibilityIdsForStudyRole$(logTemplateStudyRoleIndex: number): Observable<string[]> {
        const studyRoleFormGroup = this.getStudyRoleFormGroup(logTemplateStudyRoleIndex);
        const responsibilityIdsFormControl = studyRoleFormGroup.controls.responsibilityIds;

        return responsibilityIdsFormControl.valueChanges.pipe(
            startWith(responsibilityIdsFormControl.value)
        );
    }

    private setOriginalStudyRoles(): void {
        this.studyRolesWithResponsibilityIds?.forEach((studyRole) => {
            this.originalStudyRoles.set(studyRole.studyRole, studyRole.responsibilityIds);
        });
    }

    private setIsStudyRoleChanged(studyRoleFormGroup: FormGroup, responsibilityIdsFormArray: FormArray): void {
        const originalStudyRoleResponsibilityIds = this.originalStudyRoles.get(studyRoleFormGroup.value.studyRole);
        const newStudyRoleResponsibilityIds = responsibilityIdsFormArray.value;

        if (!originalStudyRoleResponsibilityIds?.length) {
            studyRoleFormGroup.patchValue({ isChanged: true });
            return;
        }

        const areResponsibilitiesUpdated = !_.isEqual(
            originalStudyRoleResponsibilityIds.sort(), newStudyRoleResponsibilityIds.sort()
        );

        if (areResponsibilitiesUpdated !== studyRoleFormGroup.value.isChanged) {
            studyRoleFormGroup.patchValue({ isChanged: areResponsibilitiesUpdated });
        }
    }

    private generateStudyRoleFormGroup(
        existingStudyRole?: StudyRole
    ): FormGroup {
        const studyRole = existingStudyRole ?? this.emptyStudyRole;
        const studyRoleId = studyRole.studyRole;
        const isNew = !existingStudyRole;

        return this.formBuilder.group({
            studyRole: this.formBuilder.control(studyRoleId, [
                Validators.required,
                notBlank
            ]),
            responsibilityIds: this.formBuilder.array(
                studyRole.responsibilityIds.map((responsibilityId) => new FormControl(responsibilityId)),
                [Validators.required]
            ),
            isNew,
            isChanged: false
        });
    }

    private getStudyRoleFormGroup(logTemplateStudyRoleIndex: number): FormGroup {
        const studyRoleFormGroup = <FormGroup> this.studyRolesFormArray.at(logTemplateStudyRoleIndex);

        return studyRoleFormGroup;
    }

    private sortStudyResponsibilities(a: StudyResponsibility, b: StudyResponsibility) {
        const indexOfStudyResponsibilityA = this.studyResponsibilities.values.indexOf(a);
        const indexOfStudyResponsibilityB = this.studyResponsibilities.values.indexOf(b);

        return indexOfStudyResponsibilityA - indexOfStudyResponsibilityB;
    }

    private get emptyStudyRole(): StudyRole {
        return {
            studyRole: '',
            responsibilityIds: []
        };
    }

    scrollToViewport() {
        setTimeout(() => {
            const container = this.studyRoleItemsRef.last.nativeElement;
            container.parentElement.scrollTop = container.offsetTop;
        });
    }

    selectStudyRole(logTemplateStudyRoleIndex: number, event: FilteredSelectEvent<StudyRoleIdName>): void {
        const [studyRole] = event.added;
        const logTemplateStudyRoleFormGroup = <FormGroup> this.studyRolesFormArray.at(logTemplateStudyRoleIndex);
        logTemplateStudyRoleFormGroup.patchValue({
            studyRole: studyRole._id
        });
    }

    onSelectionCleared(index: number): void {
        this.onFilterChanged('', index);
    }

    getFilteredList(): void {
        merge(
            this.studyRolesFormArray.valueChanges,
            this.studyRolesListChanged$
        ).pipe(
            takeUntil(this.destroy$),
            map(() => {
                const studyRolesFormArr = this.studyRolesFormArray.value;
                const filteredOptionsMap = {};

                const alreadyPicketRoles = studyRolesFormArr.map((el) => el.studyRole);
                studyRolesFormArr.forEach((element, elIndex) => {

                    const rolesToRemove = alreadyPicketRoles.filter((apr) => apr !== element.studyRole);
                    const filteredOtions = this.studyRolesList.filter(
                        (studyRole) => !rolesToRemove.includes(studyRole._id)
                    );
                    filteredOptionsMap[elIndex] = filteredOtions;
                });
                return filteredOptionsMap;
            }),
            tap(() => {
                this.studyRolesFormArray.value.forEach((roleForm, formIndex) => {
                    this.filterValues[formIndex] = this.studyRolesMap.get(roleForm.studyRole);
                });
            })
        ).subscribe((options) => {
            this.rolesAndResponsibilitiesOptions = options;
        });


    }

    onFilterChanged(searchTerm: string, index: number): void {
        this.filterValues[index] = searchTerm;
        if (searchTerm === '') {
            const formGroup = this.studyRolesFormArray.at(index) as FormGroup;
            formGroup.patchValue({
                studyRole: undefined,
                isChanged: true
            });

            // Emit an event to re-trigger the filtered list update if needed
            this.studyRolesListChanged.next();
        }
    }

    fitToViewport(index: number): void {
        const dropdownElement = this.studyRoleSearchElements.toArray()[index]?.scrollViewport?.elementRef?.nativeElement;
        if (!dropdownElement) {
            return;
        }
        // @ts-expect-error The type was never defined here, as it is being referenced as an HTML elements
        dropdownElement.scrollIntoViewIfNeeded();
    }

}
